<template>
<b-col>
  <div class="d-flex">
    <h5 class="d-inline">Reviewers</h5>
    <b-button v-b-modal.reviewers-category-modal variant="info" size="sm" class="ms-auto me-1">Add category</b-button>
  </div>


  <b-list-group class="mt-3">
    <b-list-group-item v-for="category in selected_reviewers_categories" :key="category.id">
      <b-row>
        <b-col>
          <b-row>
            <h6>{{category.name}}:</h6>
          </b-row>
        </b-col>
        <b-col cols="auto">
          <b-button-group>
            <b-button @click="openModalAuthor(category)" variant="outline-primary">Add Author</b-button>
            <b-button @click="deleteCategory(category)" variant="outline-danger">Delete</b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row v-for="author in category.authors">
        <b-col>
          {{author.name}}
        </b-col>

      </b-row>
    </b-list-group-item>
  </b-list-group>



  <reviewers-category-modal
      @select-reviewer-category="addReviewerCategory"
      :reviewers-categories="reviewers_categories"
  />

  <author-modal
      v-if="authors"
      @selectAuthor="addAuthor"
      :authors="authors"
      :idModal = "'add-author-modal-reviewer'"
      />
</b-col>
</template>

<script>
import api from "@/api/api";
import ReviewersCategoryModal from "@/components/shared/reviewersCategoryModal.vue";
import AuthorModal from "@/components/shared/authorModal.vue";

export default {
  name: "reviewers",
  components: {AuthorModal, ReviewersCategoryModal},
  props: ['authors', 'reviewers_categories', 'authorCategoryReviewers'],
  data(){
    return {
      selected_reviewers_categories: [],
      selectedCategory: null,
    }
  },
  mounted() {
    this.processAuthorCategoryReviewers();
  },
  methods: {
    addReviewerCategory(reviewerCategory) {
      if (this.selected_reviewers_categories.includes(reviewerCategory)) {
        return;
      }

      this.selected_reviewers_categories.push(reviewerCategory);
      this.$bvModal.hide('reviewers-category-modal')
    },
    deleteCategory(category){
      this.selected_reviewers_categories = this.selected_reviewers_categories.filter(c => c.id !== category.id);
      // Удалить связанные записи из authorCategoryReviewers
      this.$emit('remove-category', category.id);
    },
    openModalAuthor(category){
      this.selectedCategory = category;
      this.$bvModal.show('add-author-modal-reviewer')
    },
    addAuthor(author){

      // find category in reviewers_categories by selectedCategory.id
      let category = this.reviewers_categories.find(c => c.id === this.selectedCategory.id);


      this.$bvModal.hide('add-author-modal-reviewer')
      this.$emit('add-author-to-category', {
          author: author,
          category: category
      });
    },
    processAuthorCategoryReviewers() {
      // Очистка предыдущих данных
      this.selected_reviewers_categories = [];

      // Обработка новых данных
      this.authorCategoryReviewers.forEach(item => {
        let category = this.selected_reviewers_categories.find(c => c.id === item.reviewersCategoryId);
        if (!category) {
          category = {
            id: item.reviewersCategoryId,
            name: item.reviewersCategory.name,
            authors: []
          };
          this.selected_reviewers_categories.push(category);
        }
        category.authors.push({
          id: item.authorId,
          name: item.author.name
          // Другие поля автора при необходимости
        });
      });
    }
  },
  watch: {
    authorCategoryReviewers: {
      immediate: true,
      handler() {
          this.processAuthorCategoryReviewers();
      }
    }
  },
}
</script>

<style scoped lang="scss">

</style>
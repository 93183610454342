<template>
  <div>
    <h6>Logo size</h6>
    <b-form-select @change="selectSize" v-model="selectedSize" :options="sizes"></b-form-select>
  </div>


</template>

<script>
export default {
  name: "logoSizeSelect",
  props:["selectedInit"],
  data() {
    return {
      selectedSize: 4,
      sizes: [
        { text: "1x1", value: 1 },
        { text: "1x2", value: 2 },
        { text: "2x1", value: 3 },
        { text: "2x2", value: 4 }
      ]
    };
  },
  methods: {
    selectSize() {
      this.$emit("size-change", this.selectedSize);
    }
  },
  mounted() {
    this.$emit("size-change", this.selectedSize);
    if (this.selectedInit) {

    }

  }
}
</script>

<style scoped lang="scss">

</style>

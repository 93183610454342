<template>
  <label class="container-checkbox">
    Сохранить вход
    <input type="checkbox" >
    <!--           checked="checked"-->
    <span class="checkmark"></span>
  </label>
</template>

<script>
export default {
  name: 'InputCheckbox'
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/color.scss";

.container-checkbox {
  position: relative;
  padding: 0.0625rem 0.25rem 0.0625rem 2.5rem;
  width: fit-content;
  cursor: pointer;
  color: $text;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border-radius: 4px;
    background-color: $white;
    border: 1px solid $light-blue-stroke;
    cursor: pointer;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 50%;
    top: 48%;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.container-checkbox:hover input ~ .checkmark {
  background-color: $white;
  border: 1px solid $light-blue-stroke;
}

.container-checkbox input:checked ~ .checkmark {
  background-color: $light-green;
  border: 1px solid $light-green;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
</style>
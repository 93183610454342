<template>
  <b-form @submit="addAuthor">
    <b-alert v-if="failedAdd" class="mt-3 text-center" show variant="danger">{{ errMsg }}</b-alert>
    <b-alert v-if="successAdd" class="mt-3 text-center" show variant="success">Successfully
      <span v-if="!this.$route.params.id">added</span>
      <span v-else>updated</span>
      !
    </b-alert>

    <b-row v-if="author.icon !== ''">
      <b-col>
        <img :src="urlConstructor(author.icon)" style="max-height: 300px">
      </b-col>
    </b-row>
    <b-form-group class="mt-3" label="Avatar">
      <b-form-file ref="fileInput" @change="onFileChange($event.target.files[0])"></b-form-file>
    </b-form-group>
    <b-row>
      <b-col>
        <b-form-group class="mt-3" label="Name">
          <b-form-input v-model="author.name" required type="text"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group class="mt-3" label="Regalia">
          <b-form-input v-model="author.regalia" required type="text"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-tabs class="mt-3">
      <b-tab class="mt-3" title="English">
<!--        <b-form-group label="Summary">-->
<!--          <b-form-textarea v-model="author.text" max-rows="10" required rows="3"></b-form-textarea>-->
<!--        </b-form-group>-->
        <h5 class="mt-3">Summary:</h5>
        <editor-with-filters v-if="!loading" :init-html="author.text" :tab="{key: ''}" class="mt-3"
                             @input-editor="updateInput"/>
      </b-tab>
      <b-tab class="mt-3" title="Thai">
        <h5 class="mt-3">Summary:</h5>
        <editor-with-filters v-if="!loading" :init-html="author.textTh" :tab="{key:'Th'}" class="mt-3"
                             @input-editor="updateInput"/>
      </b-tab>
      <b-tab class="mt-3" title="Vietnamese">
        <h5 class="mt-3">Summary:</h5>
        <editor-with-filters v-if="!loading" :init-html="author.textVi" :tab="{key:'Vi'}" class="mt-3"
                             @input-editor="updateInput"/>
      </b-tab>
      <b-tab class="mt-3" title="Malay">
        <h5 class="mt-3">Summary:</h5>
        <editor-with-filters v-if="!loading" :init-html="author.textMs" :tab="{key:'Ms'}" class="mt-3"
                             @input-editor="updateInput"/>
      </b-tab>
      <b-tab class="mt-3" title="Khmer">

        <h5 class="mt-3">Summary:</h5>
        <editor-with-filters v-if="!loading" :init-html="author.textKm" :tab="{key:'Km'}" class="mt-3"
                             @input-editor="updateInput"/>
      </b-tab>
      <b-tab class="mt-3" title="Myanmar">

        <h5 class="mt-3">Summary:</h5>
        <editor-with-filters v-if="!loading" :init-html="author.textMm" :tab="{key:'Mm'}" class="mt-3"
                             @input-editor="updateInput"/>
      </b-tab>
    </b-tabs>
    <b-form-group class="mt-3" label="LinkedIn">
      <b-form-input v-model="author.linkedin" type="text"></b-form-input>
    </b-form-group>
    <b-form-group class="mt-3" label="Twitter">
      <b-form-input v-model="author.twitter" type="text"></b-form-input>
    </b-form-group>
    <b-form-group class="mt-3" label="Website">
      <b-form-input v-model="author.website" type="text"></b-form-input>
    </b-form-group>
    <b-button class="mt-3 w-100" type="submit" variant="success">Submit</b-button>
  </b-form>
</template>

<script>
import api from "@/api/api";
import files from "@/services/files";
import EditorWithFilters from "@/components/shared/EditorWithFilters.vue";

export default {
  name: 'CreateAuthor',
  components: {EditorWithFilters},
  data() {
    return {
      author: {
        name: '',
        regalia: '',
        text: '',
        textTh: '',
        textVi: '',
        textMs: '',
        textKm: '',
        textMm: '',
        linkedin: '',
        twitter: '',
        website: '',
        icon: '',
      },
      file: null,
      successAdd: false,
      failedAdd: false,
      errMsg: '',
      loading: true,
    };
  },
  methods: {
    onFileChange(file) {
      files.createImage(file, "images/authors").then((response) => {
        this.author.icon = response.data;
      });
    },
    addAuthor(e) {
      e.preventDefault();

      api.createAuthor(this.author)
          .then((response) => {
            this.successAdd = true;
            setTimeout(() => {
              this.successAdd = false;
            }, 5000);
            this.author = {
              ID: '',
              name: '',
              regalia: '',
              text: '',
              textTh: '',
              textVi: '',
              textMs: '',
              textKm: '',
              linkedin: '',
              twitter: '',
              website: '',
              icon: '',
            };
            this.file = null;
            this.$refs.fileInput.reset();
            if (this.$route.params.id) {
              setTimeout(() => {
                this.$router.push('/authors');
              }, 2000)
            }
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              this.failedAdd = true;
              this.errMsg = error.response.data.message;
              setTimeout(() => {
                this.failedAdd = false;
              }, 5000);
            } else {
              console.log('An error occurred', error);
            }
          });
    },

    //construct url with env variable
    urlConstructor(url) {
      return process.env.VUE_APP_API_URL + url;
    },
    updateInput(data) {
      console.log(data)
      this.author["text" + data.key] = data.html
    },
  },

  mounted() {
    if (this.$route.params.id) {
      api.getAuthorById(this.$route.params.id)
          .then((response) => {
            this.author = response.data;
            this.loading = false;
          })
    } else {
      this.loading = false;
    }
  }
};
</script>

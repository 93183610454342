import api from "@/api/api";


const createImage = async (file, path) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('path', path);
    return api.uploadImage(formData)
}

export default {
    createImage
}
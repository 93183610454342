<template>
  <b-container>
    <b-row>
      <b-col class="d-flex">
        <h1>Create Quiz</h1>
        <div class="ms-auto">
          <b-button variant="success" @click="saveQuiz">Save Quiz</b-button>
        </div>

      </b-col>
    </b-row>
    <b-row>
      <h5>Name</h5>
      <b-tabs content-class="mt-3">
        <b-tab active title="En">
          <b-form-group>
            <b-form-input v-model="quiz.name"/>
          </b-form-group>
        </b-tab>
        <b-tab title="Th">
          <b-form-group>
            <b-form-input v-model="quiz.nameTh"/>
          </b-form-group>
        </b-tab>
        <b-tab title="Vi">
          <b-form-group>
            <b-form-input v-model="quiz.nameVi"/>
          </b-form-group>
        </b-tab>
        <b-tab title="Ms">
          <b-form-group>
            <b-form-input v-model="quiz.nameMs"/>
          </b-form-group>
        </b-tab>
        <b-tab title="Km">
          <b-form-group>
            <b-form-input v-model="quiz.nameKm"/>
          </b-form-group>
        </b-tab>

        <b-tab title="Mm">
          <b-form-group>
            <b-form-input v-model="quiz.nameMm"/>
          </b-form-group>
        </b-tab>
      </b-tabs>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <div class="accordion" role="tablist">
          <b-card v-for="(question, index) in quiz.questions" class="mb-1" no-body>
            <b-card-header class="p-1" header-tag="header" role="tab">
              <b-button v-b-toggle="`question-`+index" block class="w-100" variant="primary">Question #{{ index + 1 }}
              </b-button>
            </b-card-header>
            <b-collapse :id="`question-`+index" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-row>
                  <b-col>
                    <h5>Question text</h5>
                    <b-tabs content-class="mt-3">
                      <b-tab active title="En">
                        <b-form-group>
                          <b-form-input v-model="question.question"/>
                        </b-form-group>
                      </b-tab>
                      <b-tab title="Th">
                        <b-form-group>
                          <b-form-input v-model="question.questionTh"/>
                        </b-form-group>
                      </b-tab>
                      <b-tab title="Vi">
                        <b-form-group>
                          <b-form-input v-model="question.questionVi"/>
                        </b-form-group>
                      </b-tab>
                      <b-tab title="Ms">
                        <b-form-group>
                          <b-form-input v-model="question.questionMs"/>
                        </b-form-group>
                      </b-tab>
                      <b-tab title="Km">
                        <b-form-group>
                          <b-form-input v-model="question.questionKm"/>
                        </b-form-group>
                      </b-tab>

                      <b-tab title="Mm">
                        <b-form-group>
                          <b-form-input v-model="question.questionMm"/>
                        </b-form-group>
                      </b-tab>
                    </b-tabs>
                  </b-col>
                </b-row>
                <hr>
                <b-table :fields="tableAnswerFields" :items="question.quizAnswers">
                  <template v-slot:cell(answer)="data">
                    <b-tabs content-class="mt-3">
                      <b-tab active title="En">
                        <b-form-group>
                          <b-form-input v-model="data.item.answer"/>
                        </b-form-group>
                      </b-tab>
                      <b-tab title="Th">
                        <b-form-group>
                          <b-form-input v-model="data.item.answerTh"/>
                        </b-form-group>
                      </b-tab>
                      <b-tab title="Vi">
                        <b-form-group>
                          <b-form-input v-model="data.item.answerVi"/>
                        </b-form-group>
                      </b-tab>
                      <b-tab title="Ms">
                        <b-form-group>
                          <b-form-input v-model="data.item.answerMs"/>
                        </b-form-group>
                      </b-tab>
                      <b-tab title="Km">
                        <b-form-group>
                          <b-form-input v-model="data.item.answerKm"/>
                        </b-form-group>
                      </b-tab>
                      <b-tab title="Mm">
                        <b-form-group>
                          <b-form-input v-model="data.item.answerMm"/>
                        </b-form-group>
                      </b-tab>
                    </b-tabs>

                    <!--                    <b-form-input v-model="data.item.answer"/>-->
                  </template>
                  <template v-slot:cell(isCorrect)="data">
                    <b-form-checkbox
                        :checked="data.item.isCorrect"
                        @change="() => setCorrectAnswer(index, data.index)"
                    />
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant="danger" @click="deleteAnswer(index, data.index)">Delete</b-button>
                  </template>
                </b-table>
                <b-row class="mt-3">
                  <b-col class="d-flex">
                    <div>
                      <b-button variant="success" @click="addAnswer(index)">Add Answer</b-button>
                    </div>
                    <div class="ms-auto">
                      <b-button variant="danger" @click="delQuestion(index)">Delete Question</b-button>
                    </div>

                  </b-col>
                </b-row>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-button class="w-100" variant="info" @click="addQuestion">Add Question</b-button>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <h5>Related to the articles:</h5>
        <b-table :items="quiz.articles" :fields="['title']"/>
      </b-col>
      <b-col>
        <h5>Related to webinars:</h5>
        <b-table :items="quiz.webinars" :fields="['title']">

        </b-table>
      </b-col>
    </b-row>
  </b-container>

</template>

<script>
import axios from "axios";

export default {
  name: "CreateQuiz",
  data() {
    return {
      quiz: {
        id: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        name: '',
        questions: [
          {
            id: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            quizID: null,
            question: '',
            questionTh: '',
            questionVi: '',
            questionMs: '',
            questionKm: '',
            questionMm: '',
            quizAnswers: [
              {
                id: null,
                createdAt: null,
                updatedAt: null,
                deletedAt: null,
                quizQuestionID: null,
                answer: '',
                answerTh: '',
                answerVi: '',
                answerMs: '',
                answerKm: '',
                answerMm: '',
                isCorrect: false,
              },
            ],
            order: 0,
          },
        ],
      },
      tableAnswerFields: ['answer', 'isCorrect', 'action'],

    }
  },

  methods: {
    addQuestion() {
      this.quiz.questions.push({
        id: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        quizID: null,
        question: '',
        quizAnswers: [
          {
            id: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            quizQuestionID: null,
            answer: '',
            isCorrect: false,
          },
        ],
        order: this.quiz.questions.length,
      })
    },
    deleteAnswer(questionIndex, answerIndex) {
      this.quiz.questions[questionIndex].quizAnswers.splice(answerIndex, 1);
    },

    addAnswer(index) {
      this.quiz.questions[index].quizAnswers.push({
        id: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        quizQuestionID: null,
        answer: '',
        isCorrect: false,
      });
    },
    setCorrectAnswer(questionIndex, answerIndex) {
      // Сначала установим все ответы как неверные
      this.quiz.questions[questionIndex].quizAnswers.forEach((answer, index) => {
        answer.isCorrect = false;
      });
      // Теперь установим выбранный ответ как верный
      this.quiz.questions[questionIndex].quizAnswers[answerIndex].isCorrect = true;
    },
    //validate all answers and questions
    validateQuiz() {
      let isValid = true;
      this.quiz.questions.forEach((question, questionIndex) => {
        if (question.question === '') {
          isValid = false;
        }
        question.quizAnswers.forEach((answer, answerIndex) => {
          if (answer.answer === '') {
            isValid = false;
          }
        });
      });
      return isValid;
    },
    saveQuiz() {
      if (!this.validateQuiz()) {
        alert('Please fill all fields');
        return;
      }
      axios.post(`${process.env.VUE_APP_API_URL}/quiz`, this.quiz, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
          .then(response => {
            this.$router.push('/quiz');
          })
          .catch(error => {
            console.error('Error creating quiz: ', error);
          });
    },
    delQuestion(index) {
      this.quiz.questions.splice(index, 1);
    }
  },
  mounted() {
    if (this.$route.params.id !== "create"){
      axios.get(`${process.env.VUE_APP_API_URL}/quiz/${this.$route.params.id}`)
          .then(response => {
            this.quiz = response.data;
          })
          .catch(error => {
            console.error('Error fetching quiz: ', error);
          });
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
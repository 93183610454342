<template>
  <b-form-group>
    <b-button class="mb-3 w-100" type="submit" variant="success" @click="saveMeeting">Submit</b-button>

    <b-row>
      <b-col>
        <b-form-group label="Title">
          <b-form-input v-model="meeting.title" type="text"></b-form-input>
        </b-form-group>

        <editor-with-filters v-if="!loading" :tab="'en'" :init-html="meeting.description" class="mt-3"
                             @input-editor="updateInput"/>

        <b-form-group class="mt-3" label="Video Link">
          <b-form-input v-model="meeting.video" type="text"></b-form-input>
        </b-form-group>

        <b-form-group v-if="isCreating" class="mt-3" label="Registration Link">
          <b-form-input v-model="meeting.registration" type="text"></b-form-input>
        </b-form-group>

        <b-form-group class="mt-3" label="Country">
          <b-select v-model="meeting.country" :options="countries"></b-select>
        </b-form-group>

        <b-row v-if="meeting.banner !== ''" class="mt-3">
          <b-col>
            <img :src="urlConstructor(meeting.banner)" style="max-height: 300px">
          </b-col>
        </b-row>

        <b-form-group class="mt-3" label="Banner">
          <b-form-file ref="fileInput" @change="onFileChange($event.target.files[0])"></b-form-file>
        </b-form-group>
      </b-col>

    </b-row>

    <b-row class="mt-3">
      <b-col class="border p-3">
        <h6>Authors</h6>
        <b-button v-b-modal.authors-modal>
          Select Authors
        </b-button>

        <author-card
            v-for="author in meeting.authors"
            :key="author.id"
            :author="author"
            :full-width="true"
            :lite="true"
            :remove-icon="true"
            :without-avatar="true"
            class="mt-3"
            @removeAuthor="removeAuthor(author)"
        />

      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group class="mt-3" label="Schedule">
          <b-form-datepicker
              v-model="date"
              class="mb-2"
          ></b-form-datepicker>
          <b-form-timepicker
              v-model="time"
          ></b-form-timepicker>
        </b-form-group>
      </b-col>
    </b-row>


    <b-button class="mt-3 w-100" type="submit" variant="success" @click="saveMeeting">Submit</b-button>

    <author-modal
        :authors="authors"
        @selectAuthor="selectAuthor"/>


  </b-form-group>
</template>

<script>
import files from "@/services/files";
import Sources from "@/components/articles/sources.vue";
import AuthorCard from "@/components/authors/authorCard.vue";
import Reviewers from "@/components/articles/reviewers.vue";
import AuthorModal from "@/components/shared/authorModal.vue";
import api from "@/api/api";
import axios from "axios";
import EditorWithFilters from "@/components/shared/EditorWithFilters.vue";

export default {
  name: "CreateMeetings",
  components: {EditorWithFilters, AuthorModal, Reviewers, AuthorCard, Sources},
  data() {
    return {
      meeting: {
        id: null,
        title: '',
        description: '',
        country: '',
        banner: '',
        authors: [],
        schedule: '',
        videoLink: '',
      },
      countries: [
        {value: '', text: 'English'},
        {value: 'km', text: 'Cambodia'},
        {value: 'ms', text: 'Malaysia'},
        {value: 'Th', text: 'Thailand'},
        {value: 'Vi', text: 'Vietnam'},
      ],
      authors: [],
      date: null,
      time: null,
      loading: true,
      isCreating: true,
    }

  },

  methods: {
    updateInput(data) {
      this.meeting.description = data.html;
    },
    urlConstructor(url) {
      return process.env.VUE_APP_API_URL + url;
    },

    onFileChange(file) {
      files.createImage(file, "images/meetings").then((response) => {
        this.meeting.banner = response.data;
      });
    },

    saveMeeting() {
      this.meeting.schedule = this.meetingSchedule;

      axios.post(`${process.env.VUE_APP_API_URL}/meetings`, this.meeting, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
          .then(response => {
            console.log('Article created: ', response.data);
            this.successAlert = true;
            this.emptyMeetings();
            this.$router.go(0);

            setTimeout(() => {
              this.successAlert = false;
            }, 5000);
          })
          .catch(error => {
            console.error('Error creating article: ', error);
          });

    },
    emptyMeetings() {
      this.meeting = {
        id: null,
        title: '',
        description: '',
        country: '',
        banner: '',
        authors: [],
        schedule: '',
        videoLink: '',
      }
    },
    selectAuthor(author) {
      this.$bvModal.hide('authors-modal')
      if (this.meeting.authors.find(item => item.id === author.id)) {
        return;
      }
      this.meeting.authors.push(author);
    },

    removeAuthor(author) {
      this.meeting.authors = this.meeting.authors.filter(item => item.id !== author.id);
    },
  },

  mounted() {
    api.getAuthors().then((response) => {
      this.authors = response.data;
    });

    if (this.$route.params.id) {
      this.isCreating = false;
      axios.get(`${process.env.VUE_APP_API_URL}/meetings/${this.$route.params.id}`)
          .then(response => {
            this.meeting = response.data;
            this.meetingSchedule = this.meeting.schedule;
            this.loading = false;
          })
          .catch(error => {
            console.error('There was an error: ', error);
          });
    } else {
      this.loading = false;
    }
  },
  computed: {
    meetingSchedule: {
      get() {
        if (!this.date || !this.time) {
          return null;
        }

        // Создаем объект Date из выбранных даты и времени
        const [year, month, day] = this.date.split('-').map(Number);
        const [hours, minutes] = this.time.split(':').map(Number);
        const scheduleDate = new Date(year, month - 1, day, hours, minutes);

        // Преобразуем в формат ISO 8601
        return scheduleDate.toISOString();
      },
      set(newValue) {
        if (!newValue) {
          this.date = null;
          this.time = null;
          return;
        }

        // Разбиваем ISO строку на дату и время
        const scheduleDate = new Date(newValue);
        this.date = scheduleDate.toISOString().split('T')[0]; // получаем только дату
        this.time = scheduleDate.toTimeString().split(':')[0] + ':' + scheduleDate.toTimeString().split(':')[1]; // получаем время HH:mm
      }
    }
  }

}
</script>


<style lang="scss" scoped>

</style>
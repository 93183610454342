<template>
  <b-modal id="reviewers-category-modal"
           hide-footer
           hide-header-close
           size="lg"
  >
    <template #modal-title>
      Select Reviewers Category
    </template>

    <b-row>
      <b-col cols="12">
        <b-form-input v-model="search"
                      class="mb-3"
                      placeholder="Search reviewers category by name..."
                      type="text"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col
             class="mb-3"
             cols="12"
      >
        <b-list-group>
          <b-list-group-item v-for="reviewerCategory in filteredReviewersCategories"
                             :key="reviewerCategory.id">
            <b-row>
              <b-col @click="selectReviewerCategory(reviewerCategory)" class="select">
                {{reviewerCategory.name}}
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>


  </b-modal>
</template>

<script>

export default {
  name: "reviewersCategoryModal",
  props: ["reviewersCategories"],
  data() {
    return {
      search: ''
    };
  },
  methods: {
    selectReviewerCategory(reviewerCategory) {
      console.log("test")
      this.$emit('select-reviewer-category', reviewerCategory);
    }
  },
  computed: {
    filteredReviewersCategories() {
      return this.reviewersCategories.filter(reviewerCategory => reviewerCategory.name.toLowerCase().includes(this.search.toLowerCase()));
    }
  }
}
</script>

<style scoped lang="scss">

.select {
  cursor: pointer;
}

</style>
<template>
  <b-container class="text-start">
    <h3>Add Category</h3>
    <b-row>
      <b-col>
        <label>Title</label>
        <b-form-input v-model="title" placeholder="Enter Title"></b-form-input>
        <label class="mt-3">TitleTh</label>
        <b-form-input v-model="titleTh" placeholder="Enter Title"></b-form-input>
        <label class="mt-3">TitleVi</label>
        <b-form-input v-model="titleVi" placeholder="Enter Title"></b-form-input>
        <label class="mt-3">TitleMs</label>
        <b-form-input v-model="titleMs" placeholder="Enter Title"></b-form-input>
        <label class="mt-3">TitleKm</label>
        <b-form-input v-model="titleKm" placeholder="Enter Title"></b-form-input>
        <label class="mt-3">TitleMm</label>
        <b-form-input v-model="titleMm" placeholder="Enter Title"></b-form-input>
      </b-col>
      <b-col>
        <label>Icon</label>
        <b-form-file
            v-model="icon"
            :state="Boolean(icon)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
        ></b-form-file>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-button @click="SendCategory" variant="success">Add</b-button>
        <b-alert v-if="success" class="mt-4" variant="success" show>Сategory added successfully</b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from "@/api/api";

export default {
  name: "AddCategories",
  data() {
    return {
      icon: null,
      title: "",
      titleTh: "",
      titleVi: "",
      titleMs: "",
      titleKm: "",
      titleMm: "",

      success: false,
      timeout: null
    }
  },
  watch: {
    success(newVal) {
      if (newVal) {
        this.timeout = setTimeout(() => {
          this.success = false;
        }, 5000);
      } else {
        clearTimeout(this.timeout);
      }
    }
  },
  methods: {
    SendCategory() {
      if (!this.title || !this.icon) {
        console.warn("Both title and icon must be provided.");
        return;
      }
      api.addCategory(this.title, this.titleTh, this.titleVi, this.titleMs, this.titleKm, this.titleMm, this.icon).then(() => {
        this.success = true
        this.$router.go(0);
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
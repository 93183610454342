<template>
  <div class="container-login">
    <div class="main-content">
      <FormAuth @success-auth="firstStepSuccess" v-if="!fa2"/>
      <FormOTP v-else/>

    </div>
  </div>
</template>

<script>
import FormAuth from "@/components/authorization/FormAuth.vue";
import FormOTP from "@/components/authorization/FormOTP.vue";
import Cookies from "js-cookie";
import router from "@/router";

export default {
  name: "PageAuthorization",
  components: {FormOTP, FormAuth},
  data() {
    return{
      fa2: false,
    }
  },

  methods: {
    firstStepSuccess(data){
      if (data==="2fa") {
        this.fa2 = true
      } else {
        router.push('/')
      }
    }

  },
  mounted() {
    let tempToken = Cookies.get("TempToken")
    if (tempToken) {
      this.fa2 = true
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/color";

.box-login {
  gap: 1.5rem;

  .title-form {
    margin: 0;
  }

  .message-success {
    color: $dark-green;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
}
</style>
<template>
  <div>
    <show-resources
        @open-edit="openEdit"
        @delete-resource="promptDeleteMeeting"
        :webinar="true"
        :resources="meetings"
    />

    <b-modal ref="confirmationModal" hide-footer hide-header-close title="Confirm Deletion">
      <p>Are you sure you want to delete this meeting?</p>
      <div>
        <b-button @click="confirmDelete" class="me-3" variant="danger">Confirm</b-button>
        <b-button @click="cancelDelete" variant="secondary">Cancel</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import ShowResources from "@/components/shared/showResources.vue";

export default {
  name: "ShowMeetings",
  components: {ShowResources},
  data() {
    return {
      meetings: [],
      meetingIdToDelete: null,
    }
  },
  methods: {
    getMeetings() {
      axios.get(`${process.env.VUE_APP_API_URL}/meetings`).then((response) => {
        this.meetings = response.data;
      });
    },
    openEdit(id) {
      this.$router.push("/professionals/meetings/edit/" + id);
    },
    promptDeleteMeeting(id) {
      this.meetingIdToDelete = id;
      this.$refs.confirmationModal.show();
    },
    confirmDelete() {
      if (this.meetingIdToDelete !== null) {
        this.deleteMeeting(this.meetingIdToDelete);
      }
      this.meetingIdToDelete = null;
      this.$refs.confirmationModal.hide();
    },

    cancelDelete() {
      this.meetingIdToDelete = null;
      this.$refs.confirmationModal.hide();
    },

    deleteMeeting(id){
      axios.delete(`${process.env.VUE_APP_API_URL}/meetings/${id}`)
          .then(response => {
            this.getMeetings();
          })
          .catch(error => {
            console.error('Error deleting article: ', error);
          });
    },
  },
  mounted() {
    this.getMeetings();
  }
}
</script>

<style lang="scss" scoped>

</style>
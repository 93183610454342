<template>
  <b-container class="text-start">
    <h3>Add Subcategory</h3>
    <b-row>
      <b-col>
        <label>Icon</label>
        <b-form-file
            v-model="icon"
            :state="Boolean(icon)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
        ></b-form-file>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <label>Category</label>
        <b-form-select v-model="selectedCategory" :options="categoryOptions"></b-form-select>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <label>Title</label>
        <b-form-input v-model="title" placeholder="Enter Title"></b-form-input>
        <label>Description</label>
        <b-form-textarea v-model="description" placeholder="Enter Description"></b-form-textarea>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <label>TitleTh</label>
        <b-form-input v-model="titleTh" placeholder="Enter Title"></b-form-input>
        <label>Description</label>
        <b-form-textarea v-model="descriptionTh" placeholder="Enter Description"></b-form-textarea>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <label>TitleVi</label>
        <b-form-input v-model="titleVi" placeholder="Enter Title"></b-form-input>
        <label>Description</label>
        <b-form-textarea v-model="descriptionVi" placeholder="Enter Description"></b-form-textarea>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <label>TitleMs</label>
        <b-form-input v-model="titleMs" placeholder="Enter Title"></b-form-input>
        <label>Description</label>
        <b-form-textarea v-model="descriptionMs" placeholder="Enter Description"></b-form-textarea>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <label>TitleKm</label>
        <b-form-input v-model="titleKm" placeholder="Enter Title"></b-form-input>
        <label>Description</label>
        <b-form-textarea v-model="descriptionKm" placeholder="Enter Description"></b-form-textarea>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <label>TitleMm</label>
        <b-form-input v-model="titleMm" placeholder="Enter Title"></b-form-input>
        <label>Description</label>
        <b-form-textarea v-model="descriptionMm" placeholder="Enter Description"></b-form-textarea>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <b-button @click="SendSubcategory" variant="success">Add</b-button>
        <b-alert v-if="success" class="mt-4" variant="success" show>Subcategory added successfully</b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from "@/api/api";

export default {
  name: "AddSubcategories",
  data() {
    return {
      icon: null,
      title: "",
      titleTh: "",
      titleVi: "",
      titleMs: "",
      titleKm: "",
      titleMm: "",
      selectedCategory: "",
      success: false,
      timeout: null,
      categories: [],
      description: "",
      descriptionTh: "",
      descriptionVi: "",
      descriptionMs: "",
      descriptionKm: "",
      descriptionMm: ""
    }
  },
  computed: {
    categoryOptions() {
      return this.categories.map(category => {
        return {text: category.Title, value: category.ID};
      });
    }
  },
  watch: {
    success(newVal) {
      if (newVal) {
        this.timeout = setTimeout(() => {
          this.success = false;
        }, 5000);
      } else {
        clearTimeout(this.timeout);
      }
    }
  },
  mounted() {
    api.getCategories().then(response => {
      this.categories = response.data;
    });
  },
  methods: {
    SendSubcategory() {
      if (!this.title || !this.icon || !this.selectedCategory || !this.description) {
        console.warn("Title, icon, category, and description must be provided.");
        return;
      }
      const subCategoryData = {
        icon: this.icon,
        title: this.title,
        titleTh: this.titleTh,
        titleVi: this.titleVi,
        titleMs: this.titleMs,
        titleKm: this.titleKm,
        titleMm: this.titleMm,
        description: this.description,
        descriptionTh: this.descriptionTh,
        descriptionVi: this.descriptionVi,
        descriptionMs: this.descriptionMs,
        descriptionKm: this.descriptionKm,
        descriptionMm: this.descriptionMm,
        categoryID: this.selectedCategory
      };

      api.addSubcategory(subCategoryData).then(() => {
        this.success = true
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>

<template>
  <b-container fluid>
    <b-row class="my-3">
      <b-col>
        <b-button class="w-100" variant="success" @click="saveVideo">Save</b-button>
      </b-col>
    </b-row>
    <b-alert v-if="successAlert" class="text-center" show variant="success">Successfully added!</b-alert>
    <b-row>
      <b-col cols="12">
        <h5 class="mb-3">Category/SubCategory</h5>
        <subcategory-selector :selected-subcategory-init="video.subcategory" @subcategory-change="selectSubCategory"/>
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-tabs content-class="mt-5">
        <b-tab v-for="tab in tabs" :key="tab.label" :title="tab.label">
          <h4>{{ tab.label }}</h4>

          <b-row>
            <b-col>
              <img v-if="video['logo'+tab.key]" :src="urlConstructor(video['logo'+tab.key])" class="w-100">
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col>
              <b-form-group label="Upload Logo">
                <b-form-file @change="uploadLogo($event.target.files[0], tab.key)" drop-placeholder="Drop file here..."
                             placeholder="Choose a file or drop it here..."></b-form-file>
                <logo-size-select class="mt-3" @size-change="selectLogoSize"/>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group label="Title">
            <b-form-input v-model="video['title'+tab.key]" placeholder="Enter title" type="text"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-3" label="Youtube URL">
            <b-form-input v-model="video['video'+tab.key]" placeholder="Enter title" type="text"></b-form-input>
          </b-form-group>
          <iframe class="mt-3" v-if="video['video'+tab.key]"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen frameborder="0"
                  height="315" :src="convertYouTubeUrl(video['video'+tab.key])"
                  title="YouTube video player"
                  width="560"></iframe>
        </b-tab>
      </b-tabs>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-button class="w-100" variant="success" @click="saveVideo">Save</b-button>
      </b-col>
    </b-row>


  </b-container>
</template>

<script>
import EditorWithFilters from "@/components/shared/EditorWithFilters.vue";
import AuthorCard from "@/components/authors/authorCard.vue";
import SubcategorySelector from "@/components/shared/subcategorySelector.vue";
import AuthorModal from "@/components/shared/authorModal.vue";
import LogoSizeSelect from "@/components/shared/logoSizeSelect.vue";
import axios from "axios";
import files from "@/services/files";

export default {
  name: "CreateVideo",
  components: {LogoSizeSelect, AuthorModal, SubcategorySelector, AuthorCard, EditorWithFilters},
  data() {
    return {
      video: {
        logo:"",
        logoKm: "",
        logoMs: "",
        logoTh: "",
        logoVi: "",
        logoMm: "",
        resourceLogoSizeID: 1,
      },
      successAlert: false,
      tabs: [
        {
          label: 'English',
          key: ''
        },
        {
          label: 'Thai',
          key: 'Th'
        },
        {
          label: 'Vietnamese',
          key: 'Vi'
        },
        {
          label: 'Malay',
          key: 'Ms'
        },
        {
          label: 'Khmere',
          key: 'Km'
        },
        {
          label: 'Myanmar',
          key: 'Mm'
        }
      ],
      loading: true,
    }
  },
  methods: {
    handleRouteChange(to, from) {
      this.$router.go(0);
    },
    saveVideo() {
      axios.post(`${process.env.VUE_APP_API_URL}/videos`, this.video, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
          .then(response => {
            this.successAlert = true;
            // this.emptyArticles();
            this.$router.go(0);

            setTimeout(() => {
              this.successAlert = false;
            }, 5000);
          })
          .catch(error => {
            console.error('Error creating article: ', error);
          });
    },
    urlConstructor(url) {
      return process.env.VUE_APP_API_URL + url;
    },

    uploadLogo(file,key) {
      files.createImage(file, "images/resources/logo").then((response) => {
        this.video['logo'+key] = response.data;
      });
    },
    selectLogoSize(size) {
      this.video.resourceLogoSizeID = size
    },
    selectSubCategory(subcategory) {
      this.video.subcategoryID = subcategory.ID
      this.video.subcategory = subcategory
    },

    convertYouTubeUrl(url) {
      return url.replace("watch?v=", "embed/");
    }

  },
  mounted() {
    if (this.$route.params.id) {
      axios.get(`${process.env.VUE_APP_API_URL}/videos/${this.$route.params.id}`)
          .then(response => {
            this.video = response.data;
            // this.article.logo = null;
            this.loading = false;
          })
          .catch(error => {
            console.error('There was an error: ', error);
          });
    } else {
      this.loading = false;
    }
  },
  watch: {
    '$route': 'handleRouteChange',
  },
}
</script>


<style lang="scss" scoped>

</style>
<template>
  <b-modal :id="idModal ? idModal : 'authors-modal'"
           hide-footer
           hide-header-close
           size="lg"
  >
    <template #modal-title>
      Select Author
    </template>

    <b-row>
      <b-col cols="12">
        <b-form-input v-model="search"
                      class="mb-3"
                      placeholder="Search authors by name..."
                      type="text"
        ></b-form-input>
      </b-col>
      <b-col v-for="author in filteredAuthors"
             :key="author.id"
             class="mb-3"
             cols="6"
      >
        <author-card :author="author"
                     :lite="true"
                     :without-avatar="true"
                     class="m-auto"
                     @selectAuthor="$emit('selectAuthor', $event)"
        />
      </b-col>
    </b-row>

  </b-modal>
</template>

<script>
import AuthorCard from "@/components/authors/authorCard.vue";

export default {
  components: {AuthorCard},
  props: ["authors", "idModal"],
  name: "authorModal",
  data() {
    return {
      search: ''
    };
  },
  computed: {
    filteredAuthors() {
      return this.authors.filter(author => author.name.toLowerCase().includes(this.search.toLowerCase()));
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div>
    <show-categories/>
    <add-categories class="mt-5"/>
  </div>


</template>

<script>
import AddCategories from "@/components/AddCategories.vue";
import ShowCategories from "@/components/ShowCategories.vue";

export default {
  name: "CategoriesView",
  components: {ShowCategories, AddCategories}
}
</script>

<style lang="scss" scoped>

</style>
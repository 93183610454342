<template>
<!--  Vue bootstrap ul li of categories-->
  <b-list-group>
    <b-list-group-item v-for="category in categories" :key="category.ID">
      <b-row>
        <b-col>
          <b-list-group-item-heading>{{category.name}}</b-list-group-item-heading>
        </b-col>
        <b-col cols="auto">
          <b-button-group>
            <b-button @click="deleteCategory(category)" variant="outline-danger">Delete</b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </b-list-group-item>
  </b-list-group>


</template>

<script>
import api from "@/api/api";

export default {
  name: "ShowWritersReviewers",
  data(){
    return {
      categories: [],
    }
  },
  methods: {
    deleteCategory(category){
      api.deleteWritersReviewers(category.id).then((res) => {
        this.categories = this.categories.filter((c) => c.id !== category.id)
      })
    }
  },
  mounted() {
    api.getWritersReviewers().then((res) => {
      this.categories = res.data;
    })
  }
}
</script>

<style scoped lang="scss">

</style>
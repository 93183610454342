import { render, staticRenderFns } from "./showResources.vue?vue&type=template&id=0c19af36&scoped=true&"
import script from "./showResources.vue?vue&type=script&lang=js&"
export * from "./showResources.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c19af36",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./subcategorySelector.vue?vue&type=template&id=274b2855&"
import script from "./subcategorySelector.vue?vue&type=script&lang=js&"
export * from "./subcategorySelector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
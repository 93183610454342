import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import menuSidebar from '@/components/shared/menuSidebar.vue';
import AuthorsView from '@/views/Authors/AuthorsView.vue';
import PartnersView from '@/views/Partners/PartnersView.vue';
import ShowArticles from '@/views/Articles/ShowArticles.vue';
import CreateArticles from '@/views/Articles/CreateArticles.vue';
import CreateAuthor from "@/views/Authors/CreateAuthor.vue";
import CreatePoster from "@/views/Posters/CreatePosters.vue";
import CategoriesView from "@/views/Navigation/CategoriesView.vue";
import ShowVideos from "@/views/Videos/ShowVideos.vue";
import CreateVideo from "@/views/Videos/CreateVideo.vue";
import ShowPosters from "@/views/Posters/ShowPoster.vue";
import CreateTaskForces from "@/views/TaskForces/CreateTaskForces.vue";
import ShowTaskForces from "@/views/TaskForces/ShowTaskForces.vue";
import ShowWritersReviewers from "@/views/Authors/WritersReviewers/ShowWritersReviewers.vue";
import CreateWritersReviewers from "@/views/Authors/WritersReviewers/CreateWritersReviewers.vue";
import ShowSubcategories from "@/views/Subcategories/ShowSubcategories.vue";
import CreateSubcategories from "@/views/Subcategories/CreateSubcategories.vue";
import ShowLocales from "@/views/Locales/ShowLocales.vue";
import PageAuth from "@/views/PageAuth.vue";
import Cookies from "js-cookie";
import UserManagement from "@/views/Users/UserManagement.vue";
import ShowWebinars from "@/views/Professionals/Webinars/ShowWebinars.vue";
import CreateWebinars from "@/views/Professionals/Webinars/CreateWebinars.vue";
import CreateProfResources from "@/views/Professionals/Resources/CreateProfResources.vue";
import ShowProfResources from "@/views/Professionals/Resources/ShowProfResources.vue";
import ShowMeeting from "@/views/Professionals/Meetings/ShowMeeting.vue";
import CreateMeeting from "@/views/Professionals/Meetings/CreateMeeting.vue";
import ShowPublication from "@/views/Professionals/Publications/ShowPublication.vue";
import CreatePublication from "@/views/Professionals/Publications/CreatePublication.vue";
import ShowNews from "@/views/Professionals/News/ShowNews.vue";
import CreateNews from "@/views/Professionals/News/CreateNews.vue";
import ShowQuiz from "@/views/Quiz/ShowQuiz.vue";
import CreateQuiz from "@/views/Quiz/CreateQuiz.vue";

Vue.use(VueRouter);

const routes = [

    {
        path: '/auth',
        component: {
            render: (h) => h('router-view'),
        },
        beforeEnter: (to, from, next) => {
            let token = Cookies.get("token")
            let refreshToken = Cookies.get("refreshToken")
            next()

        },
        children: [
            {
                path: '/',
                name: 'auth',
                component: PageAuth,
            },
            // {
            //   path: 'password-recovery',
            //   name: 'password-recovery',
            //   component: PagePasswordRecovery,
            // },
        ],
    },


    {
        path: '/',
        name: 'home',
        components: {
            default: HomeView,
            menu: menuSidebar,
        },
    },
    {
        path: '/authors',
        name: 'authors',
        components: {
            default: AuthorsView,
            menu: menuSidebar,
        },
    },

    {
        path: '/authors/create',
        name: 'authors.create',
        components: {
            default: CreateAuthor,
            menu: menuSidebar,
        },
    },

    {
        path: '/authors/edit/:id',
        name: 'authors.create',
        components: {
            default: CreateAuthor,
            menu: menuSidebar,
        },
    },

    {
        path: '/authors/writers_reviewers/',
        name: 'authors.writers_reviewers',
        components: {
            default: ShowWritersReviewers,
            menu: menuSidebar,
        },
    },

    {
        path: '/authors/writers_reviewers/create',
        name: 'authors.writers_reviewers.create',
        components: {
            default: CreateWritersReviewers,
            menu: menuSidebar,
        },
    },


    {
        path: '/task_forces',
        name: 'task_forces',
        components: {
            default: ShowTaskForces,
            menu: menuSidebar,
        },
    },

    {
        path: '/task_forces/create',
        name: 'task_forces.create',
        components: {
            default: CreateTaskForces,
            menu: menuSidebar,
        },
    },

    {
        path: '/task_forces/create/:id',
        name: 'task_forces.create',
        components: {
            default: CreateTaskForces,
            menu: menuSidebar,
        },
    },

    {
        path: '/articles',
        name: 'articles',
        components: {
            default: ShowArticles,
            menu: menuSidebar,
        },
    },
    {
        path: '/articles/create',
        name: 'articles.create',
        components: {
            default: CreateArticles,
            menu: menuSidebar,
        },
    },
    {
        path: '/articles/edit/:id',
        name: 'articles.edit',
        components: {
            default: CreateArticles,
            menu: menuSidebar,
        },
    },
    {
        path: '/videos',
        name: 'videos',
        components: {
            default: ShowVideos,
            menu: menuSidebar,
        },
    },
    {
        path: '/videos/create',
        name: 'videos',
        components: {
            default: CreateVideo,
            menu: menuSidebar,
        },
    },
    {
        path: '/videos/edit/:id',
        name: 'videos',
        components: {
            default: CreateVideo,
            menu: menuSidebar,
        },
    },
    {
        path: '/posters',
        name: 'posters',
        components: {
            default: ShowPosters,
            menu: menuSidebar,
        },
    },

    {
        path: '/posters/create',
        name: 'posters.create',
        components: {
            default: CreatePoster,
            menu: menuSidebar,
        },
    },

    {
        path: '/posters/edit/:id',
        name: 'posters',
        components: {
            default: CreatePoster,
            menu: menuSidebar,
        },
    },

    {
        path: '/partners',
        name: 'partners',
        components: {
            default: PartnersView,
            menu: menuSidebar,
        },
    },

    {
        path: '/navigation/categories',
        name: 'categories',
        components: {
            default: CategoriesView,
            menu: menuSidebar,
        }
    },

    {
        path: '/navigation/subcategories',
        name: 'categories',
        components: {
            default: ShowSubcategories,
            menu: menuSidebar,
        }
    },

    {
        path: '/navigation/subcategories/create',
        name: 'categories.create',
        components: {
            default: CreateSubcategories,
            menu: menuSidebar,
        }
    },

    {
        path: '/navigation/subcategories/create/:id',
        name: 'categories.create',
        components: {
            default: CreateSubcategories,
            menu: menuSidebar,
        }
    },

    {
        path: '/locales',
        name: 'locales',
        components: {
            default: ShowLocales,
            menu: menuSidebar,
        }
    },
    {
        path: '/users',
        name: 'users',
        components: {
            default: UserManagement,
            menu: menuSidebar,
        }
    },
    {
        path: '/professionals/webinars',
        name: 'webinars',
        components: {
            default: ShowWebinars,
            menu: menuSidebar,
        }
    },
    {
        path: '/professionals/webinars/create',
        name: 'webinars',
        components: {
            default: CreateWebinars,
            menu: menuSidebar,
        }
    },

    {
        path: '/professionals/webinars/edit/:id',
        name: 'webinars.edit',
        components: {
            default: CreateWebinars,
            menu: menuSidebar,
        },
    },

    {
        path: '/professionals/resources',
        name: 'resources',
        components: {
            default: ShowProfResources,
            menu: menuSidebar,
        }
    },
    {
        path: '/professionals/resources/create',
        name: 'resources.create',
        components: {
            default: CreateProfResources,
            menu: menuSidebar,
        }
    },
    {
        path: '/professionals/resources/edit/:id',
        name: 'webinars.edit',
        components: {
            default: CreateProfResources,
            menu: menuSidebar,
        },
    },


    {
        path: '/professionals/publications',
        name: 'resources',
        components: {
            default: ShowPublication,
            menu: menuSidebar,
        }
    },
    {
        path: '/professionals/publications/create',
        name: 'resources.create',
        components: {
            default: CreatePublication,
            menu: menuSidebar,
        }
    },
    {
        path: '/professionals/publications/edit/:id',
        name: 'webinars.edit',
        components: {
            default: CreatePublication,
            menu: menuSidebar,
        },
    },


    {
        path: '/professionals/meetings',
        name: 'meetings',
        components: {
            default: ShowMeeting,
            menu: menuSidebar,
        }
    },
    {
        path: '/professionals/meetings/create',
        name: 'meetings.create',
        components: {
            default: CreateMeeting,
            menu: menuSidebar,
        }
    },
    {
        path: '/professionals/meetings/edit/:id',
        name: 'meetings.edit',
        components: {
            default: CreateMeeting,
            menu: menuSidebar,
        },
    },


    {
        path: '/professionals/news',
        name: 'news',
        components: {
            default: ShowNews,
            menu: menuSidebar,
        }
    },
    {
        path: '/professionals/news/create',
        name: 'news.create',
        components: {
            default: CreateNews,
            menu: menuSidebar,
        }
    },
    {
        path: '/professionals/news/edit/:id',
        name: 'meetings.edit',
        components: {
            default: CreateNews,
            menu: menuSidebar,
        },
    },

    {
        path: '/quiz',
        name: 'quiz.show',
        components: {
            default: ShowQuiz,
            menu: menuSidebar,
        }
    },
    {
        path: '/quiz/create/:id',
        name: 'quiz.create',
        components: {
            default: CreateQuiz,
            menu: menuSidebar,
        }
    },

    // {
    //     path: '/files',
    //     name: 'files',
    //     components: {
    //         default: FilesView,
    //         menu: menuSidebar,
    //     },
    // },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;

<template>
  <b-container>
    <b-table striped hover :items="categories" :fields="fields" @row-clicked="rowClicked">
      <template #cell(IconLink)="data">
        <img :src="apiUrl+data.value" alt="Icon" height="50">
      </template>
      <template v-for="langKey in ['Title', 'TitleTh', 'TitleVi', 'TitleMs', 'TitleKm','TitleMm']" #[`cell(${langKey})`]="data">
        <b-form-input v-if="data.item === editableItem" v-model="data.item[langKey]"
                      @blur="updateCategory(data.item)"></b-form-input>
        <span v-else>{{ data.item[langKey] }}</span>
      </template>
      <template #cell(Order)="data">
        <b-form-input v-if="data.item === editableItem" type="number" v-model="data.item.Order"
                      @blur="updateCategory(data.item)"></b-form-input>
        <span v-else>{{ data.item.Order }}</span>
      </template>
    </b-table>
  </b-container>
</template>

<script>
import api from "@/api/api";

export default {
  name: "ShowCategories",
  data() {
    return {
      categories: [],
      editableItem: null,
      fields: [
        {key: "Title"},
        {key: "TitleTh"},
        {key: "TitleVi"},
        {key: "TitleMs"},
        {key: "TitleKm"},
        {key:"TitleMm"},
        {key: "IconLink", label: "Icon"},
        {key: "Order"}
      ],
      apiUrl: process.env.VUE_APP_API_URL
    };
  },
  mounted() {
    api.getCategories().then(response => {
      this.categories = response.data;
    });
  },
  methods: {
    rowClicked(item) {
      this.editableItem = item;
    },
    updateCategory(updatedCategory) {
      api.updateCategory(updatedCategory).then(() => {
        console.log('Category updated');
        this.editableItem = null;
      });
    }
  }
};
</script>

<style scoped lang="scss">
</style>

<template>
  <b-modal id="articles-modal"
           hide-footer
           hide-header-close
           size="lg"
  >
    <template #modal-title>
      Select Article
    </template>

    <b-row>
      <b-col cols="12">
        <b-form-input v-model="search"
                      class="mb-3"
                      placeholder="Search article by title..."
                      type="text"
        ></b-form-input>
      </b-col>
      <b-col md="4" sm="6" xs="12" v-for="article in filteredArticle" :key="article.id" class="mb-4">
        <b-card
                @click="$emit('select-article', article)"
                :title="article.title"
                :img-src="getImageUrl(article.logo)"
                img-alt="Image"
                img-top
                tag="article"
                style="cursor: pointer">
          <b-card-text>
            {{ article.subcategory.title }}
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

  </b-modal>
</template>

<script>

export default {
  props: ["articles",],
  name: "articleModal",
  data() {
    return {
      search: ''
    };
  },
  computed: {
    filteredArticle() {
      return this.articles.filter(author => author.title.toLowerCase().includes(this.search.toLowerCase()));
    }
  },
  methods:{
    getImageUrl(imageName) {
      if (imageName) {
        return process.env.VUE_APP_API_URL + imageName;
      } else {
        return require("@/assets/icon/no-avatar.png");
      }
    },
  }

}
</script>

<style lang="scss" scoped>

</style>

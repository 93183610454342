import { render, staticRenderFns } from "./CreatePublication.vue?vue&type=template&id=26eb0ca2&scoped=true&"
import script from "./CreatePublication.vue?vue&type=script&lang=js&"
export * from "./CreatePublication.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26eb0ca2",
  null
  
)

export default component.exports
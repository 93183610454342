import apiUser from "@/api/api"
import Cookies from 'js-cookie';
import store from "@/store";
import api from "@/api/api";
import jwtDecode from "jwt-decode";
import router from "@/router";


const authUser = async (data) => {
    try {
        const response = await apiUser.auth(data);
        if (response.data.TempToken) {
            Cookies.set('TempToken', response.data.TempToken);
            return "2fa";
        } else {
            Cookies.set('token', response.data.token, { expires: new Date(new Date().getTime() + 15 * 60 * 1000) });
            Cookies.set('refreshToken', response.data.refreshToken, { expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000) } );
            let decodedToken = jwtDecode(response.data.token)
            store.commit("setRole", decodedToken.role)

            return "true";
        }
    } catch (error) {
        console.log(error)
        if (error.response) {
            return "false";
        }
        return "false"
    }
};


const sendOTP = async (data) => {
    let payload = {
        "auth_code": data,
        "temp_token": Cookies.get("TempToken")
    }
    try {
        let response = await api.otpCheck(payload)
        Cookies.set('token', response.data.token, { expires: new Date(new Date().getTime() + 15 * 60 * 1000) });
        Cookies.set('refreshToken', response.data.refreshToken, { expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000) } );
        Cookies.remove("TempToken")

        let decodedToken = jwtDecode(response.data.token)
        store.commit("setUser", decodedToken)
        return "true"

    } catch (error) {
        return "false"
    }
}

const refreshJWT = () => {
    api.refreshJWT()
        .then((response)=>{
            Cookies.set('token', response.data.token, { expires: new Date(new Date().getTime() + 15 * 60 * 1000) });
            Cookies.set('refreshToken', response.data.refreshToken, { expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000) } );
            console.log("success auth")
            let decodedToken = jwtDecode(response.data.token)
            store.commit("setRole", decodedToken.role)
            if (!router.currentRoute.path.startsWith('/')) {
                router.push('/');
            }
        })
        .catch(()=>{
            console.log("fail auth")
            if (router.currentRoute.path !== '/auth') {
                router.push('/auth');
            }

        })

}

const exit = () => {
    Cookies.remove('token')
    Cookies.remove('refreshToken')
    window.location.href = "/"
}

export default {
    authUser,
    sendOTP,
    exit,
    refreshJWT
}
<template>
  <div class="text-start mt-5">
    <b-form @submit.prevent="addNewPartner">
      <b-form-group label="Name">
        <b-form-input v-model="partner.name"></b-form-input>
      </b-form-group>

      <b-form-group label="Link" class="mt-3">
        <b-form-input v-model="partner.link"></b-form-input>
      </b-form-group>

      <b-form-group class="mt-3">
        <b-form-checkbox v-model="partner.en">English</b-form-checkbox>
        <b-form-checkbox v-model="partner.th">Thai</b-form-checkbox>
        <b-form-checkbox v-model="partner.vi">Vietnamese</b-form-checkbox>
        <b-form-checkbox v-model="partner.ms">Malay</b-form-checkbox>
        <b-form-checkbox v-model="partner.km">Khmer</b-form-checkbox>
        <b-form-checkbox v-model="partner.mm">Myanmar</b-form-checkbox>
      </b-form-group>

      <b-form-group label="Logo">
        <b-form-file @change="handleFileChange" ref="fileInput"></b-form-file>
      </b-form-group>

      <b-button class="mt-3" type="submit" variant="primary">Add Partner</b-button>
    </b-form>
  </div>
</template>

<script>
import api from "@/api/api";

export default {
  name: "AddPartners",
  data() {
    return {
      partner: {
        name: "",
        link: "",
        en: false,
        th: false,
        vi: false,
        ms: false,
        km: false,
        mm: false,
        logo: null
      }
    };
  },
  methods: {
    handleFileChange(event) {
      console.log("INFO")
      this.partner.logo = event.target.files[0];
    },
    async addNewPartner() {
      try {
        const response = await api.addPartner(this.partner);
        if (response.status === 200) {
          console.log("Partner added successfully!");
          this.$router.go(0);
        }
      } catch (error) {
        console.error("There was an error adding the partner:", error);
      }
    }
  }
};
</script>

<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>Quizzes</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-table :items="quizzes" :fields="fields">
        <template v-slot:cell(action)="data">
          <b-button variant="primary" @click="editQuiz(data.item.id)">Edit</b-button>
          <b-button variant="danger" class="ms-3" @click="deleteQuiz(data.item.id)">Delete</b-button>
        </template>
      </b-table>
    </b-row>
    <b-row>
      <b-table />
    </b-row>

  </b-container>

</template>

<script>
import axios from "axios";

export default {
  name: "ShowQuiz",
  data(){
    return {
      quizzes: [],
      fields: ['name', 'action']
    }
  },
  methods: {
    fetchQuizzes(){
      axios.get(`${process.env.VUE_APP_API_URL}/quiz`, {
        params: {
          page: 1,
          page_size: 1000
        }
      })
          .then(response => {
            this.quizzes = response.data;
          })
          .catch(error => {
            console.error('Error fetching quizzes: ', error);
          });
    },
    editQuiz(id){
      this.$router.push(`/quiz/create/${id}`);
    },
    deleteQuiz(id){
      axios.delete(`${process.env.VUE_APP_API_URL}/quiz/${id}`)
          .then(response => {
            this.fetchQuizzes();
          })
          .catch(error => {
            console.error('Error deleting quiz: ', error);
          });
    }
  },
  mounted() {
    this.fetchQuizzes();
  }
}
</script>

<style scoped lang="scss">

</style>
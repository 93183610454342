<template>
  <b-form-group>
    <b-button class="mb-3 w-100" type="submit" variant="success" @click="saveWebinar">Submit</b-button>

    <b-row>
      <b-col>
        <b-form-group label="Title">
          <b-form-input v-model="webinar.title" type="text"></b-form-input>
        </b-form-group>
        <editor-with-filters v-if="!loading" :tab="'en'" :init-html="webinar.description" class="mt-3"
                             @input-editor="updateInput"/>
        <b-form-group label="Video Link">
          <b-form-input v-model="webinar.video" type="text"></b-form-input>
        </b-form-group>
        <b-form-group class="mt-3" label="Country">
          <b-select v-model="webinar.country" :options="countries"></b-select>
        </b-form-group>

        <b-row v-if="webinar.banner !== ''" class="mt-3">
          <b-col>
            <img :src="urlConstructor(webinar.banner)" style="max-height: 300px">
          </b-col>
        </b-row>

        <b-form-group class="mt-3" label="Banner">
          <b-form-file ref="fileInput" @change="onFileChange($event.target.files[0])"></b-form-file>
        </b-form-group>
      </b-col>

    </b-row>

    <b-row class="mt-3">
      <b-col class="border p-3">
        <h6>Authors</h6>
        <b-button v-b-modal.authors-modal>
          Select Authors
        </b-button>

        <author-card
            v-for="author in webinar.authors"
            :key="author.id"
            :author="author"
            :full-width="true"
            :lite="true"
            :remove-icon="true"
            :without-avatar="true"
            class="mt-3"
            @removeAuthor="removeAuthor(author)"
        />

      </b-col>
    </b-row>

    <b-row>
      <b-col class="border p-3">
        <h6>Quizzes</h6>
        <b-button v-b-modal.quizzes-modal>
          Select Quizzes
        </b-button>
        <b-table v-if="webinar.quizzes.length !== 0" :items="webinar.quizzes"
                 :fields="['name', 'action']"
        >
          <template v-slot:cell(action)="data">
            <b-button variant="primary" @click="editQuiz(data.item.id)">Edit</b-button>
            <b-button variant="danger" class="ms-3" @click="deleteQuiz(data.item.id)">Delete</b-button>
          </template>
        </b-table>
        <p v-else>No quizzes</p>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group class="mt-3" label="Schedule">
          <b-form-datepicker
              v-model="date"
              class="mb-2"
          ></b-form-datepicker>
          <b-form-timepicker
              v-model="time"
          ></b-form-timepicker>
        </b-form-group>
      </b-col>
    </b-row>


    <b-button class="mt-3 w-100" type="submit" variant="success" @click="saveWebinar">Submit</b-button>

    <author-modal
        :authors="authors"
        @selectAuthor="selectAuthor"/>
    <quizzes-modal
        :quizzes="quizzes"
        @select-quiz="selectQuiz"/>


  </b-form-group>
</template>

<script>
import files from "@/services/files";
import Sources from "@/components/articles/sources.vue";
import AuthorCard from "@/components/authors/authorCard.vue";
import Reviewers from "@/components/articles/reviewers.vue";
import AuthorModal from "@/components/shared/authorModal.vue";
import api from "@/api/api";
import axios from "axios";
import EditorWithFilters from "@/components/shared/EditorWithFilters.vue";
import QuizzesModal from "@/components/shared/quizzesModal.vue";

export default {
  name: "CreateWebinars",
  components: {QuizzesModal, EditorWithFilters, AuthorModal, Reviewers, AuthorCard, Sources},
  data() {
    return {
      webinar: {
        id: null,
        title: '',
        description: '',
        country: '',
        banner: '',
        authors: [],
        schedule: '',
        videoLink: '',
        quizzes: [],
      },
      countries: [
        {value: '', text: 'English'},
        {value: 'km', text: 'Cambodia'},
        {value: 'ms', text: 'Malaysia'},
        {value: 'th', text: 'Thailand'},
        {value: 'vi', text: 'Vietnam'},
      ],
      authors: [],
      date: null,
      time: null,
      loading: true,
      quizzes: [],
    }

  },

  methods: {
    urlConstructor(url) {
      return process.env.VUE_APP_API_URL + url;
    },

    onFileChange(file) {
      files.createImage(file, "images/webinars").then((response) => {
        this.webinar.banner = response.data;
      });
    },

    saveWebinar() {
      this.webinar.schedule = this.webinarSchedule;

      axios.post(`${process.env.VUE_APP_API_URL}/webinars`, this.webinar, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
          .then(response => {
            console.log('Article created: ', response.data);
            this.successAlert = true;
            this.emptyWebinars();
            this.$router.go(0);

            setTimeout(() => {
              this.successAlert = false;
            }, 5000);
          })
          .catch(error => {
            console.error('Error creating article: ', error);
          });

    },
    emptyWebinars() {
      this.webinar = {
        id: null,
        title: '',
        description: '',
        country: '',
        banner: '',
        authors: [],
        schedule: '',
        videoLink: '',
      }
    },
    selectAuthor(author) {
      this.$bvModal.hide('authors-modal')
      if (this.webinar.authors.find(item => item.id === author.id)) {
        return;
      }
      this.webinar.authors.push(author);
    },

    removeAuthor(author) {
      this.webinar.authors = this.webinar.authors.filter(item => item.id !== author.id);
    },

    updateInput(data) {
      this.webinar.description = data.html;
    },

    selectQuiz(quiz) {
      this.$bvModal.hide('quizzes-modal')
      if (this.webinar.quizzes.find(item => item.id === quiz.id)) {
        return;
      }
      this.webinar.quizzes.push(quiz);
    },

    editQuiz(id) {
      window.open(this.$router.resolve(`/quiz/create/${id}`).href, '_blank');
    },

    //delete from array
    deleteQuiz(id) {
      this.webinar.quizzes = this.webinar.quizzes.filter(item => item.id !== id);
    }

  },

  mounted() {
    api.getAuthors().then((response) => {
      this.authors = response.data;
    });
    axios.get(`${process.env.VUE_APP_API_URL}/quiz`, {
      params: {
        page: 1,
        page_size: 1000
      }
    })
        .then(response => {
          this.quizzes = response.data;
        })
        .catch(error => {
          console.error('Error fetching quizzes: ', error);
        });

    if (this.$route.params.id) {
      axios.get(`${process.env.VUE_APP_API_URL}/webinars/${this.$route.params.id}`)
          .then(response => {
            this.webinar = response.data;
            this.webinarSchedule = this.webinar.schedule;
            this.loading = false;
          })
          .catch(error => {
            console.error('There was an error: ', error);
          });
    } else {
      this.loading = false;
    }
  },
  computed: {
    webinarSchedule: {
      get() {
        if (!this.date || !this.time) {
          return null;
        }

        // Создаем объект Date из выбранных даты и времени
        const [year, month, day] = this.date.split('-').map(Number);
        const [hours, minutes] = this.time.split(':').map(Number);
        const scheduleDate = new Date(year, month - 1, day, hours, minutes);

        // Преобразуем в формат ISO 8601
        return scheduleDate.toISOString();
      },
      set(newValue) {
        if (!newValue) {
          this.date = null;
          this.time = null;
          return;
        }

        // Разбиваем ISO строку на дату и время
        const scheduleDate = new Date(newValue);
        this.date = scheduleDate.toISOString().split('T')[0]; // получаем только дату
        this.time = scheduleDate.toTimeString().split(':')[0] + ':' + scheduleDate.toTimeString().split(':')[1]; // получаем время HH:mm
      }
    }
  }

}
</script>


<style lang="scss" scoped>

</style>
<template>
  <b-modal id="quizzes-modal"
           hide-footer
           hide-header-close
           size="lg"
  >
<template #modal-title>
      Select Quiz
    </template>

    <b-row>
      <b-col cols="12">
        <b-table :items="quizzes" :fields="fields" @row-clicked="onRowClicked" class="cursor-pointer-table">

        </b-table>
      </b-col>
    </b-row>
  </b-modal>

</template>

<script>
export default {
  name: "quizzesModal",
  props: ["quizzes"],
  data() {
    return {
      fields: ['name']
    };
  },
  methods: {
    onRowClicked(item){
      this.$emit('select-quiz', item);
    }
  }

}
</script>

<style scoped lang="scss">

::v-deep  {
  .cursor-pointer-table tbody td {
    cursor: pointer;
  }
}


</style>
<template>
  <div>

    <b-button class="mb-3 w-100" type="submit" variant="success" @click="saveWebinar()">Submit</b-button>

    <b-form-group label="Title">
      <b-form-input v-model="resource.title" type="text"></b-form-input>
    </b-form-group>


    <editor-with-filters v-if="!loading" :tab="'en'" :init-html="resource.description" class="mt-3"
                         @input-editor="updateInput"/>

    <b-form-group class="mt-3" label="Link">
      <b-form-input v-model="resource.link" type="text"></b-form-input>
    </b-form-group>

    <b-form-group class="mt-3" label="Name Link">
      <b-form-input v-model="resource.nameLink" type="text"></b-form-input>
    </b-form-group>

    <b-row v-if="resource.banner" class="mt-3">
      <b-col>
        <img :src="urlConstructor(resource.banner)"  style="max-height: 300px">
      </b-col>
    </b-row>

    <b-form-group class="mt-3" label="Banner">
      <b-form-file ref="fileInput" @change="onFileChange($event.target.files[0])"></b-form-file>
    </b-form-group>

    <b-button class="mt-3 w-100" type="submit" variant="success" @click="saveWebinar()">Submit</b-button>


  </div>

</template>

<script>
import files from "@/services/files";
import axios from "axios";
import EditorWithFilters from "@/components/shared/EditorWithFilters.vue";

export default {
  name: "CreateProfResources",
  components: {EditorWithFilters},
  data() {
    return {
      resource: {
        title: "",
        description: "",
        link: "",
        banner: "",
      },
      loading: true,
    }
  },
  methods:{
    updateInput(data) {
      this.resource.description = data.html;
    },
    urlConstructor(url) {
      return process.env.VUE_APP_API_URL + url;
    },

    saveWebinar() {
      axios.post(`${process.env.VUE_APP_API_URL}/resources`, this.resource, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
          .then(response => {
            this.$router.go(0);

            setTimeout(() => {
              this.successAlert = false;
            }, 5000);
          })
          .catch(error => {
            console.error('Error creating article: ', error);
          });

    },

    onFileChange(file) {
      files.createImage(file, "images/prof/resources").then((response) => {
        this.resource.banner = response.data;
      });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      axios.get(`${process.env.VUE_APP_API_URL}/resources/${this.$route.params.id}`)
          .then(response => {
            this.resource = response.data;
            this.loading = false;
          })
          .catch(error => {
            console.error('There was an error: ', error);
          });
    } else {
      this.loading = false;
    }
  }
}
</script>

<style scoped lang="scss">

</style>
<template>
  <b-container class="d-flex flex-column">
    <button class="btn-go-back" @click="goBackToList">
      <img alt="" src="@/assets/icon/keyboard_backspace_FILL0_wght400_GRAD0_opsz24.svg">
      Back to list
    </button>
    <form v-if="!user.id" class="mt-5">
      <div class="mb-3">
        <label for="InputName" class="form-label">Username</label>
        <input type="text" v-model="user.username" class="form-control" id="InputName" aria-describedby="emailHelp">
      </div>
      <div class="mb-3">
        <label for="InputEmail" class="form-label">Email address</label>
        <input type="email" v-model="user.email"  class="form-control" id="InputEmail" aria-describedby="emailHelp">
      </div>
      <div class="d-flex gap-3">
        <b-button @click="createUser" variant="success">Create</b-button>
      </div>
      <b-alert v-if="showError" class="mt-3" variant="danger" show>{{errMsg}}</b-alert>
    </form>
    <b-container v-else class="text-center">
      <b-row>
        <b-col>
          <h3>Email:</h3>
          <h5>{{user.email}}</h5>
        </b-col>
        <b-col>
          <h3>Password:</h3>
          <h5>{{user.password}}</h5>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
import ButtonSecondary from "@/components/shared/ButtonSecondary.vue";
import ModalDelete from "@/components/users/ModalDelete.vue";
import api from "@/api/api";
import validator from 'email-validator';

export default {
  name: 'CreateUsers',
  components: {ModalDelete, ButtonSecondary},
  data() {
    return {
      password: '',
      showPassword: false,
      user:{},
      showError: false,
      errMsg: "test"
    };
  },
  methods: {
    goBackToList() {
      this.$emit('back-to-list');
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    createUser () {
      if (!validator.validate(this.user.email)) {
        this.errMsg = "Invalid email address"
        this.showError = true
        return;
      }
      api.createUser(this.user)
          .then((response)=>{
            this.user = response.data
            this.$emit('add-user', this.user);
          })
          .
          catch((err)=>{
            this.showError = true
            this.errMsg = err.response.data.error
          })
    }
  },
}
</script>


<style scoped lang="scss">
.btn-go-back {
  background: #e1e1e1;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  border: none;
  width: fit-content;

  img {
    margin-right: 0.5rem;
  }
}

//form {
//  width: 60%;
//}

.btn-primary {
  background: #3767CF;
}
.eye-icon{
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-50%, -50%);
}
.input-password{
  padding-right: 3rem;
}
.btn-danger{

}
</style>
<template>
  <div>
    <b-row>
      <b-col md="3" sm="6" xs="12" v-for="author in authors" :key="author.id" class="mb-4">
          <author-card
              :author="author"
              @editAuthor="editAuthor(author.id)"
              @confirmDelete="confirmDelete(author.id)"
          />
      </b-col>
    </b-row>

    <b-modal id="confirm-delete" ref="confirmModal" hide-header-close hide-footer>
      <template #modal-title>
        Confirm Deletion
      </template>
      <p>Are you sure you want to delete this author?</p>
      <b-button variant="danger" @click="deleteConfirmed">Yes</b-button>
      <b-button class="ms-2" variant="secondary" @click="deleteCancelled">No</b-button>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api/api";
import AuthorCard from "@/components/authors/authorCard.vue";

export default {
  name: "AuthorsView",
  components: {AuthorCard},
  data() {
    return {
      authors: [],
      idToDelete: null
    }
  },

  mounted() {
    api.getAuthors().then((response) => {
      this.authors = response.data;
    });
  },
  methods:{
    getImageUrl(imageName) {
      if (imageName !== "null") {
        return process.env.VUE_APP_API_URL + imageName;
      } else {
        return require("@/assets/icon/no-avatar.png");
      }
    },

    editAuthor(id) {
      this.$router.push("/authors/edit/" + id);
    },

    confirmDelete(id) {
      this.idToDelete = id;
      this.$refs.confirmModal.show();
    },
    deleteConfirmed() {
      api.deleteAuthor(this.idToDelete).then((response) => {
        this.authors = this.authors.filter((author) => author.ID !== this.idToDelete);
        this.$refs.confirmModal.hide();
        api.getAuthors().then((response) => {
          this.authors = response.data;
        });
      });
    },
    deleteCancelled() {
      this.idToDelete = null;
      this.$refs.confirmModal.hide();
    }
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .card-img-top {
    height: 300px;
    object-fit: cover;
  }
}

</style>

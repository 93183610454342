<template>
  <b-container fluid>
    <b-row class="my-3">
      <b-col>
        <b-button class="w-100" variant="success" @click="saveNews()">Save</b-button>
      </b-col>
    </b-row>

    <b-form-group label="Title">
      <b-form-input v-model="news.title" placeholder="Enter title" type="text"></b-form-input>
    </b-form-group>

    <b-form-group class="mt-3" label="Description">
      <b-form-textarea v-model="news.description" placeholder="Enter description" type="text"></b-form-textarea>
    </b-form-group>

    <b-form-group class="mt-3" label="Keywords">
      <b-form-input v-model="news.keywords" placeholder="Enter keywords" type="text"></b-form-input>
    </b-form-group>

    <b-row>
      <b-col>
        <img v-if="news.logo" :src="urlConstructor(news.logo)" class="w-100">
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <b-form-group label="Upload Logo">
          <b-form-file drop-placeholder="Drop file here..." placeholder="Choose a file or drop it here..."
                       @change="uploadLogo($event.target.files[0])"></b-form-file>
        </b-form-group>
      </b-col>
    </b-row>

    <editor-with-filters v-if="!loading" :init-html="news.body" :tab="'en'" class="mt-3"
                         @input-editor="updateInput"/>


    <b-row class="my-3">
      <b-col>
        <b-button class="w-100" variant="success" @click="saveNews()">Save</b-button>
      </b-col>
    </b-row>
  </b-container>

</template>

<script>
import EditorWithFilters from "@/components/shared/EditorWithFilters.vue";
import axios from "axios";
import LogoSizeSelect from "@/components/shared/logoSizeSelect.vue";
import files from "@/services/files";

export default {
  name: "CreateNews",
  components: {LogoSizeSelect, EditorWithFilters},
  data() {
    return {
      news: {
        title: "",
        body: "",
        logo: "",
        keywords: "",
      },
      loading: true,
    }
  },
  methods: {
    updateInput(data) {
      this.news.body = data.html;
    },
    saveNews(){
      axios.post(`${process.env.VUE_APP_API_URL}/news`, this.news)
          .then(response => {
            this.$router.go(0);
          })
          .catch(error => {
            console.error('There was an error: ', error);
          });
    },
    urlConstructor(url) {
      return process.env.VUE_APP_API_URL + url;
    },
    uploadLogo(file) {
      files.createImage(file, "images/news/logo").then((response) => {
        this.news.logo = response.data;
      });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      axios.get(`${process.env.VUE_APP_API_URL}/news/${this.$route.params.id}`)
          .then(response => {
            this.news = response.data;
            // this.article.logo = null;
            this.loading = false;
          })
          .catch(error => {
            console.error('There was an error: ', error);
          });
    } else {
      this.loading = false;
    }
  }
}
</script>

<style scoped lang="scss">

</style>
<template>
  <div>
    <b-list-group>
      <b-list-group-item v-for="taskForce in taskForces" :key="taskForce.id" class="d-flex">
        <p class="p-0 mt-auto mb-auto">{{ taskForce.title }}</p>
        <b-button class="ms-auto me-1" size="sm" variant="info" @click="editTaskForce(taskForce.id)">Edit</b-button>
        <b-button class="ms-0 me-0" size="sm" variant="danger" @click="confirmDelete(taskForce.id)">Delete</b-button>
      </b-list-group-item>
    </b-list-group>

    <b-modal id="confirm-delete" ref="confirmModal" hide-footer hide-header-close>
      <template #modal-title>
        Confirm Deletion
      </template>
      <p>Are you sure you want to delete this task force?</p>
      <b-button variant="danger" @click="deleteConfirmed">Yes</b-button>
      <b-button class="ms-2" variant="secondary" @click="deleteConfirmed">No</b-button>
    </b-modal>
  </div>


</template>

<script>
import api from "@/api/api";

export default {
  name: "ShowTaskForces",
  data() {
    return {
      taskForces: [],
      idToDelete: null
    }
  },
  mounted() {
    this.getTaskForces();
  },
  methods: {
    getTaskForces() {
      api.getTaskForces()
          .then((response) => {
            this.taskForces = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    editTaskForce(id) {
      this.$router.push("/task_forces/create/" + id)
    },

    confirmDelete(id) {
      this.idToDelete = id;
      this.$refs.confirmModal.show();
    },
    deleteConfirmed() {
      api.deleteTaskForce(this.idToDelete).then((response) => {
        this.taskForces = this.taskForces.filter((taskForce) => taskForce.id !== this.idToDelete);
        this.$refs.confirmModal.hide();
        this.getTaskForces();
      });
    },
    deleteCancelled() {
      this.idToDelete = null;
      this.$refs.confirmModal.hide();
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
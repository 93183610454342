<template>
  <div  id="app">
    <router-view  @toggle-sidebar="toggleSidebar" name="menu"/>
    <router-view name="authWindow"/>
    <b-container :class="{'sidebar-open': sidebarOpen}" class="my-5">
      <b-card>
        <router-view/>
      </b-card>
    </b-container>

  </div>
</template>

<script>

import auth from "@/services/auth";

export default {
  methods: {
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    }
  },
  data(){
    return {
      sidebarOpen: false,
    }
  },
  mounted() {
    auth.refreshJWT()
  }

}
</script>

<style lang="scss">
body {
  background: #dedcdc !important;
}
.sidebar-open {
  padding-left: 350px !important;  // Replace with actual width of sidebar
}

</style>

<template>
  <b-card :class="{'lite': lite, 'none-hover': removeIcon}"
          :title="poster.title"
          :img-src="getImageUrl(poster.logo)"
          img-alt="Image"
          img-top
          tag="article"
          style="max-width: 20rem;"
          :style="{ maxWidth: fullWidth ? '100%' : '20rem' }"
          @click="selectPoster(poster)"
  >
    <div v-if="!lite" class="d-flex">
      <b-button @click="editPoster(poster.id)" class="m-auto" variant="primary">Read More</b-button>
      <b-button @click="confirmDelete(poster.id)" class="m-auto" variant="danger">Delete</b-button>
    </div>
    <!--    Remove icon absolut right-top-->
    <b-icon v-if="removeIcon"
            icon="x-circle"
            class="x-cross"
            variant="danger"
            @click="removePoster(poster)"/>
  </b-card>
</template>

<script>
export default {
  props:["poster", "lite", "withoutAvatar", "fullWidth", "removeIcon"],
  name: "posterCard",
  methods: {
    getImageUrl(imageName) {
      if (this.withoutAvatar) {
        return null;
      }

      if (imageName !== "null") {
        return process.env.VUE_APP_API_URL + imageName;
      } else {
        return require("@/assets/icon/no-avatar.png");
      }
    },
    editPoster(id) {
      this.$emit("editPoster", id);
    },
    confirmDelete(id) {
      this.$emit("confirmDelete", id);
    },
    selectPoster(poster) {
      this.$emit("select-poster", poster);
    },
    removePoster(poster) {
      this.$emit("remove-poster", poster);
    }
  }
}
</script>

<style scoped lang="scss">

.lite {
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2);
  }

}

.none-hover {
  &:hover {
    box-shadow: none;
    cursor: default;
  }
}

.x-cross{
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

</style>
<template>
  <b-list-group>
    <b-list-group-item v-for="subcategory in subcategories" :key="subcategory.ID">
      <b-row>
        <b-col style="cursor: pointer" @click="$router.push('/navigation/subcategories/create/'+subcategory.ID)">
          {{subcategory.Title}}
        </b-col>
        <b-col class="text-end">
          <img :src="getImageUrl(subcategory.IconLink)">
        </b-col>
        <b-col cols="auto">
          <b-button-group>
            <b-button @click="deleteSubCategory(subcategory)" variant="outline-danger">Delete</b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </b-list-group-item>
  </b-list-group>


</template>
<script>
import axios from "axios";
import router from "@/router";

export default {
  name: "ShowSubcategories",
  data(){
    return {
      subcategories: [],
    }
  },
  methods: {
    router() {
      return router
    },
    //get urlConstructor
    getImageUrl(imageName) {
      if (imageName) {
        return process.env.VUE_APP_API_URL + imageName;
      }
    },
    deleteSubCategory(subcategory) {
      axios.delete(process.env.VUE_APP_API_URL + "/subcategories_new/" + subcategory.ID).then((res) => {
        this.$router.go();
      })
    }
  },
  mounted() {
    //get api url from env
    let url =  process.env.VUE_APP_API_URL

    axios.get(url + "/subcategories_new/list?page_size=1000").then((res) => {
      this.subcategories = res.data;
    })
  }
}
</script>

<style scoped lang="scss">

</style>
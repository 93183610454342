<template>
    <b-table striped hover :items="subcategories" :fields="fields">
      <template #cell(IconLink)="data">
        <img :src="apiUrl+data.value" alt="Icon" height="50">
      </template>
      <template #cell(CategoryID)="data">
        {{ getCategoryNameById(data.value) }}
      </template>
    </b-table>
</template>

<script>
import api from "@/api/api";

export default {
  name: "ShowSubcategories",
  data() {
    return {
      subcategories: [],
      categories: [],
      fields: [
        {
          key: "Title"
        },
        {
          key: "TitleTh"
        },
        {
          key: "TitleVi"
        },
        {
          key: "TitleMs"
        },
        {
          key: "TitleKm"
        },
        {
          key: "Description",
        },
        {
          key: "DescriptionTh",
        },
        {
          key: "DescriptionVi",
        },
        {
          key: "DescriptionMs",
        },
        {
          key: "DescriptionKm",
        },
        {
          key: "IconLink",
          label: "Icon",
        },
        {
          key: "Order",
        },
        {
          key: "CategoryID",
          label: "Category",
        },
      ],
      apiUrl: process.env.VUE_APP_API_URL
    };
  },
  mounted() {
    api.getSubcategories().then((response) => {
      this.subcategories = response.data;
    });
    api.getCategories().then((response) => {
      this.categories = response.data;
    });
  },
  methods: {
    getCategoryNameById(id) {
      const category = this.categories.find(cat => cat.ID === id);
      return category ? category.Title : 'Unknown';
    }
  }
};
</script>

<style scoped lang="scss">
</style>

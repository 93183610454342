<template>
  <b-card :class="{'lite': lite, 'none-hover': removeIcon}"
          :title="author.name"
          :img-src="getImageUrl(author.icon)"
          img-alt="Image"
          img-top
          tag="article"
          style="max-width: 20rem;"
          :style="{ maxWidth: fullWidth ? '100%' : '20rem' }"
          @click="selectAuthor(author)"
  >
    <b-card-text>
      {{ author.regalia }}
    </b-card-text>
    <b-input v-if="order" type="number" v-model="author.order">

    </b-input>
    <div v-if="!lite" class="d-flex">
      <b-button @click="editAuthor(author.ID)" class="m-auto" variant="primary">Read More</b-button>
      <b-button @click="confirmDelete(author.ID)" class="m-auto" variant="danger">Delete</b-button>
    </div>
<!--    Remove icon absolut right-top-->
    <b-icon v-if="removeIcon"
            icon="x-circle"
            class="x-cross"
            variant="danger"
            @click="removeAuthor(author)"/>
  </b-card>
</template>

<script>
export default {
  props:["author", "lite", "withoutAvatar", "fullWidth", "removeIcon", "order"],
  name: "authorCard",
  methods: {
    getImageUrl(imageName) {
      if (this.withoutAvatar) {
        return null;
      }

      if (imageName !== "null") {
        return process.env.VUE_APP_API_URL + imageName;
      } else {
        return require("@/assets/icon/no-avatar.png");
      }
    },
    editAuthor(id) {
      this.$emit("editAuthor", id);
    },
    confirmDelete(id) {
      this.$emit("confirmDelete", id);
    },
    selectAuthor(author) {
      this.$emit("selectAuthor", author);
    },
    removeAuthor(author) {
      this.$emit("removeAuthor", author);
    }
  }
}
</script>

<style scoped lang="scss">

.lite {
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2);
  }

}

.none-hover {
  &:hover {
    box-shadow: none;
    cursor: default;
  }
}

.x-cross{
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

</style>
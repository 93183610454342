<template>
  <div>
    <sidebar-menu @toggle-collapse="toggleSidebar"
                  :collapsed="true"
                  :showChild="false"
                  :menu="witchMenu()" />
  </div>
</template>


<script>
export default {
  name: "menuSidebar",
  data() {
    return {
      menu: [
        {
          header: true,
          title: 'Main Navigation',
          hiddenOnCollapse: true
        },
        {
          href: '/',
          title: 'Dashboard',
          icon: 'fa-solid fa-gauge'
        },
        {
          href: '/authors',
          title: 'Authors',
          icon: 'fa-solid fa-users',
          child: [
            {
              href:'/authors/create',
              title: 'Create Author',
            },
            {
              href:'/task_forces',
              title: 'Task Forces',
              child: [
                {
                  href:'/task_forces/create',
                  title: 'Create Task Force',
                }
              ]
            },
            {
              href:'/authors/writers_reviewers/',
              title: 'Writers & Reviewers Category',
              child: [
                {
                  href:'/authors/writers_reviewers/create',
                  title: 'Create W&R Category',
                }
              ]
            },
          ]
        },
        {
          href: '/articles',
          title: 'Articles',
          icon: 'fa-solid fa-newspaper',
          child: [
            {
              href:'/articles/create',
              title: 'Create Article',
            },
          ]
        },
        {
          href: '/videos',
          title: 'Videos',
          icon: 'fa-solid fa-video',
          child: [
            {
              href:'/videos/create',
              title: 'Create Video',
            }
          ]
        },
        {
          href: '/posters',
          title: 'Posters',
          icon: 'fa-solid fa-image',
          child: [
            {
              href:'/posters/create',
              title: 'Create Poster',
            }
          ]
        },
        {
          title: 'Professionals',
          icon: 'fa-solid fa-user-md',
          child: [
            {
              href:'/professionals/webinars',
              title: 'Webinars',
              child: [
                {
                  href:'/professionals/webinars/create',
                  title: 'Create Webinar',
                }
              ]
            },
            {
              href:'/professionals/resources',
              title: 'Resources',
              child: [
                {
                  href:'/professionals/resources/create',
                  title: 'Create Resource',
                }
              ]
            },
            {
              href:'/professionals/meetings',
              title: 'Meeting',
              child: [
                {
                  href:'/professionals/meetings/create',
                  title: 'Create Meeting',
                }
              ]
            },
            {
              href:'/professionals/publications',
              title: 'Publications',
              child: [
                {
                  href:'/professionals/publications/create',
                  title: 'Create Publications',
                }
              ]
            },
            {
              href:'/professionals/news',
              title: 'News',
              child: [
                {
                  href:'/professionals/news/create',
                  title: 'Create News',
                }
              ]
            },
          ]
        },
        {
          href: '/quiz',
          title: 'Quiz',
          icon: 'fa-solid fa-question',
          child: [
            {
              href:'/quiz/create/create',
              title: 'Create Quiz',
            }
          ]
        },
        {
          href: '/partners',
          title: 'Partners',
          icon: 'fa-solid fa-handshake'
        },
        {
          title: 'Navigation',
          icon: 'fa-solid fa-compass',
          child: [
            {
              href:'/navigation/categories',
              title: 'Categories',
            },
            {
              href:'/navigation/subcategories',
              title: 'Subcategories',
              child: [
                {
                  href:'/navigation/subcategories/create',
                  title: 'Create Subcategory',
                }
              ]
            },
          ]
        },
        {
          href: '/users',
          title: 'User management',
          icon: 'fa-solid fa-user',
        },
        {
          href: '/files',
          title: 'Files',
          icon: 'fa-solid fa-hard-drive'
        },
        {
          href: '/locales',
          title: 'Locales',
          icon: 'fa-solid fa-language'
        }
      ],
      menuEditor:[
        {
          href: '/articles',
          title: 'Articles',
          icon: 'fa-solid fa-newspaper',
          child: [
            {
              href:'/articles/create',
              title: 'Create Article',
            },
          ]
        },
      ]
    }
  },
  methods: {
    witchMenu(){
      if(this.$store.state.role === 1){
        return this.menuEditor
      }else{
        return this.menu
      }
    },
    toggleSidebar(){
      console.log('test')
      this.$emit('toggle-sidebar')
    }
  }

}
</script>

<style scoped lang="scss">

</style>
<template>

  <div>
    <show-partners/>
    <add-partners/>
  </div>

</template>

<script>
import ShowPartners from "@/components/ShowPartners.vue";
import AddPartners from "@/components/AddPartners.vue";

export default {
  name: "PartnersView",
  components: {AddPartners, ShowPartners}
}
</script>

<style scoped lang="scss">

</style>
<template>
  <div>
    <b-row>
      <b-col class="d-flex">
        <b-nav class="ms-auto me-0" pills>
          <b-nav-item :active="Object.keys(selectedCategory).length === 0"
                      @click="ChangeCategory({})"
          >All</b-nav-item>
          <b-nav-item @click="ChangeCategory(category)"
                      v-for="category in categories"
                      :key="category.ID"
                      :active="category.ID === selectedCategory.ID"
                      variant="info">{{category.Title}}</b-nav-item>
        </b-nav>
      </b-col>
    </b-row>
    <b-row v-if="!(Object.keys(selectedCategory).length === 0)" class="mt-3">
      <b-col class="d-flex">
        <b-form-select @change="fetchArticles" class="ms-auto me-0" v-model="selectedSubcategoryId" :options="modifiedSubcategoryOptions"></b-form-select>
      </b-col>
    </b-row>
    <hr class="mt-5">
    <show-resources
        @open-edit="openEdit"
        @delete-resource="promptDeleteArticle"
        :resources="articles"
    />

    <b-modal ref="confirmationModal" hide-footer hide-header-close title="Confirm Deletion">
      <p>Are you sure you want to delete this article?</p>
      <div>
        <b-button @click="confirmDelete" class="me-3" variant="danger">Confirm</b-button>
        <b-button @click="cancelDelete" variant="secondary">Cancel</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import api from "@/api/api";
import ShowResources from "@/components/shared/showResources.vue";

export default {
  name: 'ShowArticles',
  components: {ShowResources},
  data() {
    return {
      articles: [],
      fields: ['Title', 'LogoSize', 'Category', 'Subcategory'],
      currentPage: 1,
      perPage: 1000,
      totalRows: 0,
      selectedCategory: {},
      selectedSubcategoryId: -1,
      categories: [],
      articleIdToDelete: null,
    };
  },
  methods: {
    openEdit(id){
      this.$router.push("/articles/edit/" + id);
    },
    ChangeCategory(category){
      this.selectedCategory = category;
      this.selectedSubcategoryId = -1;
      this.fetchArticles();
    },
    fetchArticles() {
      axios.get(`${process.env.VUE_APP_API_URL}/articles/list`, {
        params: {
          page: this.currentPage,
          page_size: this.perPage,
          category: this.selectedCategory.ID,
          subcategory: this.selectedSubcategoryId
        }
      })
          .then(response => {
            this.articles = response.data;
            this.totalRows = this.articles.length;
          })
          .catch(error => {
            console.error('Error fetching articles: ', error);
          });
    },
    promptDeleteArticle(id) {
      this.articleIdToDelete = id;
      this.$refs.confirmationModal.show();
    },
    confirmDelete() {
      if (this.articleIdToDelete !== null) {
        this.deleteArticle(this.articleIdToDelete);
      }
      this.articleIdToDelete = null;
      this.$refs.confirmationModal.hide();
    },
    cancelDelete() {
      this.articleIdToDelete = null;
      this.$refs.confirmationModal.hide();
    },
    deleteArticle(id){
      axios.delete(`${process.env.VUE_APP_API_URL}/articles/${id}`)
          .then(response => {
            this.fetchArticles();
          })
          .catch(error => {
            console.error('Error deleting article: ', error);
          });
    },
  },
  watch: {
    currentPage() {
      this.fetchArticles();
    },
  },
  mounted() {
    this.fetchArticles();
    api.getCategories()
        .then(response => {
          this.categories = response.data;
        });
  },
  computed: {
    modifiedSubcategoryOptions() {
      if (!this.selectedCategory) return [{ value: null, text: 'Select an subcategory' }];
      const modifiedOptions = this.selectedCategory.Subcategories.map(option => {
        return {
          value: option.ID,
          text: option.Title,
        };
      });
      return [{ value: -1, text: 'Select an subcategory' }, ...modifiedOptions];
    }
  }
};
</script>


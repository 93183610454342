<template>
  <button class="btn-main" @click="handleClick">{{ buttonTitle }}</button>
</template>

<script>
export default {
  name: 'ButtonMain',
  props: {
    buttonTitle: String
  },
  methods: {
    handleClick() {
      if (this.link !== undefined) {
        this.$router.push(this.link);
      } else{
        this.$emit('click');
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/color.scss";
.btn-main{
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  border-radius: 8px;
  background: $accent;
  padding: 0.875rem 2rem;
  color: #FFF;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;

}
</style>

export default {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // Проверяем, было ли событие инициировано внутри самой таблицы
            if (el.contains(event.target)) {
                return; // Если да, то просто возвращаемся из функции
            }

            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
};

<template>
  <button @click="click" class="btn-secondary" :class="{ 'red': colorButton }">
    {{buttonTitle}}
  </button>
</template>

<script>
export default{
  name: "ButtonSecondary",
  props: {
    link: String,
    buttonTitle: String,
    colorButton: Boolean,
  },
  methods: {
    click(){
      if (this.link !== undefined) {
        this.$router.push(this.link);
      } else{
        this.$emit('click');
      }
    }
  }
}
</script>

<style scoped lang="scss">
.btn-secondary{
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  border-radius: 8px;
  background: #FFF;
  padding: 0.875rem 2rem;
  color: #1D1A31;
  border: 1px solid #D4E1EE;
  outline: none;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}
.red{
  background: #eb4747;
  color: #fff;
  border: none;
}
</style>
<template>
  <b-modal id="videos-modal"
           hide-footer
           hide-header-close
           size="lg"
  >
    <template #modal-title>
      Select Video
    </template>

    <b-row>
      <b-col cols="12">
        <b-form-input v-model="search"
                      class="mb-3"
                      placeholder="Search videos by title..."
                      type="text"
        ></b-form-input>
      </b-col>
      <b-col v-for="video in filteredVideos"
             :key="video.id"
             class="mb-3"
             cols="6"
      >
        <video-card :video="video"
                     :lite="true"

                     class="m-auto"
                     @select-video="$emit('select-video', $event)"
        />
      </b-col>
    </b-row>

  </b-modal>
</template>

<script>
import VideoCard from "@/components/videos/videoCard.vue";

export default {
  components: {VideoCard},
  props: ["videos"],
  name: "videoModal",
  data() {
    return {
      search: ''
    };
  },
  computed: {
    filteredVideos() {
      return this.videos.filter(video => video.title.toLowerCase().includes(this.search.toLowerCase()));
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div>
    <show-resources
        @open-edit="openEdit"
        @delete-resource="promptDeleteWebinar"
        :webinar="true"
        :resources="webinars"
    />

    <b-modal ref="confirmationModal" hide-footer hide-header-close title="Confirm Deletion">
      <p>Are you sure you want to delete this webinar?</p>
      <div>
        <b-button @click="confirmDelete" class="me-3" variant="danger">Confirm</b-button>
        <b-button @click="cancelDelete" variant="secondary">Cancel</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import ShowResources from "@/components/shared/showResources.vue";

export default {
  name: "ShowWebinars",
  components: {ShowResources},
  data() {
    return {
      webinars: [],
      webinarIdToDelete: null,
    }
  },
  methods: {
    getWebinars() {
      axios.get(`${process.env.VUE_APP_API_URL}/webinars`).then((response) => {
        this.webinars = response.data;
      });
    },
    openEdit(id) {
      this.$router.push("/professionals/webinars/edit/" + id);
    },
    promptDeleteWebinar(id) {
      this.webinarIdToDelete = id;
      this.$refs.confirmationModal.show();
    },
    confirmDelete() {
      if (this.webinarIdToDelete !== null) {
        this.deleteWebinar(this.webinarIdToDelete);
      }
      this.webinarIdToDelete = null;
      this.$refs.confirmationModal.hide();
    },

    cancelDelete() {
      this.webinarIdToDelete = null;
      this.$refs.confirmationModal.hide();
    },

    deleteWebinar(id){
      axios.delete(`${process.env.VUE_APP_API_URL}/webinars/${id}`)
          .then(response => {
            this.getWebinars();
          })
          .catch(error => {
            console.error('Error deleting article: ', error);
          });
    },
  },
  mounted() {
    this.getWebinars();
  }
}
</script>

<style lang="scss" scoped>

</style>
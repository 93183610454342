<template>
  <div>
    <b-form-select v-model="selectedCategory" @change="onCategoryChange">
      <option v-for="category in categories" :key="category.id" :value="category">
        {{ category.Title }}
      </option>
    </b-form-select>

    <b-form-select class="ms-3" v-if="selectedCategory" @change="onSubcategoryChange" v-model="selectedSubcategory">
      <option v-for="subcategory in selectedCategory.Subcategories" :key="subcategory.id" :value="subcategory">
        {{ subcategory.Title }}
      </option>
    </b-form-select>
  </div>

</template>

<script>
import api from '@/api/api';
export default {
  name: 'SubcategorySelector',
  props:['selectedSubcategoryInit'],
  data() {
    return {
      selectedCategory: null,
      selectedSubcategory: null,
      categories: [],
      subcategories: [],
    };
  },
  created() {
    this.fetchCategories();
  },
  methods: {
    fetchCategories() {
      // Replace this with your actual API call
      api.getCategoriesFull()
          .then(response => {
            this.categories = response.data;
          })
          .catch(error => {
            console.error('An error occurred:', error);
          });
    },
    onCategoryChange() {
      const selected = this.categories.find(cat => cat.id === this.selectedCategory);
      if (selected) {
        this.$emit('category-change', selected);
        this.subcategories = selected.Subcategories;
      }
    },
    onSubcategoryChange(){
      this.$emit('subcategory-change', this.selectedSubcategory)
    }
  },
  mounted() {
      this.selectedSubcategory = this.selectedSubcategoryInit;
      this.selectedCategory = this.categories.find(cat =>
          cat.Subcategories.some(subcat => subcat.ID === this.selectedSubcategory.ID)
      );

  },
  watch: {
    selectedSubcategoryInit(){
      this.selectedSubcategory = this.selectedSubcategoryInit;
      this.selectedCategory = this.categories.find(cat =>
          cat.Subcategories.some(subcat => subcat.ID === this.selectedSubcategory.ID)
      );
    }
  }
};
</script>

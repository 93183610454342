<template>
  <b-form-group>
    <b-row>
      <b-col>
        <b-form-group label="Name">
          <b-form-input v-model="category.name" type="text"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-button @click="saveCategory" class="mt-3 w-100" type="submit" variant="success">Submit</b-button>

  </b-form-group>
</template>


<script>
import api from "@/api/api";

export default {
  name: "CreateWritersReviewers",
  data(){
    return {
      category: {
        name: '',
      }
    }
  },
  methods: {
    saveCategory(e){
      api.saveWritersReviewers(this.category).then((res) => {
        this.$router.push("/authors/writers_reviewers")
      })
    }

  }
}
</script>

<style scoped lang="scss">

</style>
<template>
  <b-container>
    <div class="section-title d-flex mb-4">
      <p class="title">
        User management
      </p>
      <div class="btn-group ms-auto me-0">
        <b-button variant="primary" @click="showCreateUser">Add user</b-button>
      </div>
    </div>
    <b-card>
      <b-table v-if="currentComponent !== CreateUsers" :items="tableData" :fields="fields" responsive>
        <template #cell(tfa)="data">
          <i v-if="data.item.tfa" class="bi bi-check-lg"></i>
          <i v-else class="bi bi-x-lg"></i>
        </template>
        <template #cell(actions)="row">
          <b-button variant="danger" @click="confirmDelete(row.item.id)">
            <i class="bi bi-trash-fill">Delete</i>
          </b-button>
        </template>
        <template #cell(editor)="data">
          <b-form-checkbox @change="updateUser(data.item)" v-model="data.item.editor" switch></b-form-checkbox>
        </template>
      </b-table>
      <component :is="currentComponent" @add-user="addUser" @back-to-list="goBackToList"></component>
    </b-card>

    <b-modal ref="deleteModal" hide-footer title="Confirmation">
      <p class="my-4">Are you sure you want to delete the user?</p>
      <b-button variant="danger" @click="deleteUser">Delete</b-button>
      <b-button variant="secondary" @click="cancelDelete">Cancel</b-button>
    </b-modal>

  </b-container>
</template>

<script>
import ButtonSecondary from "@/components/shared/ButtonSecondary.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import CreateUsers from "@/components/users/CreateUsers.vue";
import ModalDelete from "@/components/users/ModalDelete.vue";
import api from "@/api/api";
import axios from "axios";

export default {
  name: 'UserManagement',
  computed: {
    CreateUsers() {
      return CreateUsers
    }
  },
  components: {ModalDelete, ButtonMain, ButtonSecondary},
  data() {
    return {
      tableData: [],
      currentComponent: null,
      userIdToDelete: null,
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'username', label: 'Name' },
        { key: 'email', label: 'Email' },
        { key: 'tfa', label: '2FA' },
        { key: 'actions', label: 'Action' },
        {key: 'editor', label: 'Editor'}
      ]

    };
  },
  mounted() {
    // this.fetchDataFromBackend();
    api.getUsers()
        .then((response)=>{
          this.tableData = response.data
          this.tableData.forEach((user)=>{
            if (user.role === 1) {
              user.editor = true;
            } else {
              user.editor = false;
            }
          })
        })

  },
  methods: {
    addUser(user){
      this.tableData.push(user);
    },
    updateUser(user) {
      if (user.editor === true) {
        user.role = 1;
      } else {
        user.role = 0;
      }
      axios.post(process.env.VUE_APP_API_URL+'/user/'+user.id, user)
          .then((response)=>{
            console.log(response);
          })
    },
    showCreateUser() {
      this.currentComponent = CreateUsers;
    },
    goBackToList() {
      this.currentComponent = null;
    },
    confirmDelete(userId) {
      this.userIdToDelete = userId;
      this.$refs.deleteModal.show();
    },
    deleteUser() {
      api.deleteUser(this.userIdToDelete)
          .then(()=>{
            api.getUsers()
                .then((response)=>{
                  this.tableData = response.data
                })
          })
      this.$refs.deleteModal.hide();
    },
    cancelDelete() {
      this.$refs.deleteModal.hide();
    }
  },
}

</script>

<style scoped lang="scss">
.section-title {
  display: flex;

  .title {
    height: fit-content;
    margin: auto 0;
  }


}

.btn-group {
  margin: auto;
  display: flex;
  gap: 1.5rem;
  //justify-content: end;

  .btn-action {
    background: none;
    padding: 0.3rem;
    border: none;
    outline: none;
  }
}
</style>
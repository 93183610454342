<template>

  <div>
<!--    table of locales-->
    <b-table striped hover class=" mt-3" :fields="fields" :items="locales"
             @row-clicked="rowClicked"
             v-click-outside="() => {editableItem = null}"
             >
      <template style="cursor: pointer" v-slot:cell(name)="data">
        {{data.item.name}}
      </template>

      <template v-for="langKey in ['en', 'ms', 'vi', 'km', 'th', 'mm']" #[`cell(${langKey})`]="data">
        <b-form-textarea rows="10" v-if="data.item === editableItem" v-model="data.item[langKey]"
                      ></b-form-textarea>
        <span v-else>{{ data.item[langKey] }}</span>
      </template>
    </b-table>
  </div>


</template>

<script>
import axios from "axios";
import clickOutsideDirective from "@/services/clickOutsideDirective";
import Vue from "vue";
Vue.directive('click-outside', clickOutsideDirective);
export default {
  name: "ShowLocales",
  data(){
    return {
      locales:[],
      fields: [
        { key: 'keyword', label: 'Keyword'},
        { key: 'en', label: 'English' },
        { key: 'ms', label: 'Malaysian' },
        { key: 'vi', label: 'Vietnamese' },
        { key: 'th', label: 'Thai' },
        { key: 'km', label: 'Khmer' },
        {key: 'mm', label: 'Myanmar'}
      ],
      editableItem: null,
    }
  },
  methods: {
    initLocales(){
      axios.get(process.env.VUE_APP_API_URL + "/locales").then((response) => {
        this.locales = response.data;
      });
    },
    rowClicked(item, index, event) {
      if (event) {
        event.stopPropagation(); // Теперь это должно работать
      }
      this.editableItem = item;
    },
  },
  mounted() {
    this.initLocales();
  },
  watch:{
    editableItem(newVal, oldVal){
      if (oldVal !== null) {
        axios.post(process.env.VUE_APP_API_URL + "/locales", oldVal).then((response) => {

        });
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
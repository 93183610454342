<template>
  <div>
    <show-resources
        @open-edit="openEdit"
        @delete-resource="promptDeleteResource"
        :webinar="true"
        :resources="resources"
    />

    <b-modal ref="confirmationModal" hide-footer hide-header-close title="Confirm Deletion">
      <p>Are you sure you want to delete this resource?</p>
      <div>
        <b-button @click="confirmDelete" class="me-3" variant="danger">Confirm</b-button>
        <b-button @click="cancelDelete" variant="secondary">Cancel</b-button>
      </div>
    </b-modal>
  </div>

</template>

<script>
import ShowResources from "@/components/shared/showResources.vue";
import axios from "axios";

export default {
  name: "ShowProfResources",
  components: {ShowResources},
  data() {
    return {
      resources: [],
      resourceIdToDelete: null,
    }
  },
  methods: {
    getResources() {
      axios.get(`${process.env.VUE_APP_API_URL}/resources`).then((response) => {
        this.resources = response.data;
      });
    },
    openEdit(id) {
      this.$router.push("/professionals/resources/edit/" + id);
    },
    promptDeleteResource(id) {
      this.resourceIdToDelete = id;
      this.$refs.confirmationModal.show();
    },
    confirmDelete() {
      if (this.resourceIdToDelete !== null) {
        this.deleteResource(this.resourceIdToDelete);
      }
      this.resourceIdToDelete = null;
      this.$refs.confirmationModal.hide();
    },

    cancelDelete() {
      this.resourceIdToDelete = null;
      this.$refs.confirmationModal.hide();
    },

    deleteResource(id){
      axios.delete(`${process.env.VUE_APP_API_URL}/resources/${id}`)
          .then(response => {
            this.getResources();
          })
          .catch(error => {
            console.error('Error deleting article: ', error);
          });
    },
  },
  mounted() {
    this.getResources();
  }
}
</script>

<style scoped lang="scss">

</style>
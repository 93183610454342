<template>
  <b-container>
    <!-- Check if partners array is empty -->
    <div v-if="partners.length === 0">
      Нет данных о партнерах.
    </div>
    <!-- If partners array is not empty, render the table -->
    <div v-else>
      <b-table striped hover :items="partners" :fields="fields" @row-clicked="rowClicked">
        <template #cell(Logo)="data">
          <img :src="apiUrl+data.value" alt="Logo" height="50">
        </template>
        <template #cell(Name)="data">
          <b-form-input v-if="data.item === editableItem" v-model="data.item.Name"
                        @change="updatePartner(data.item)"></b-form-input>
          <span v-else>{{ data.item.Name }}</span>
        </template>
        <template v-for="langKey in ['En', 'Th', 'Vi', 'Ms', 'Km','Mm']" #[`cell(${langKey})`]="data">
          <b-form-checkbox v-if="data.item === editableItem" v-model="data.item[langKey]"
                           @change="updatePartner(data.item)"></b-form-checkbox>
          <span v-else>{{ data.item[langKey] ? '✔️' : '❌' }}</span>
        </template>
        <template #cell(Link)="data">
          <b-form-input v-if="data.item === editableItem" v-model="data.item.Link"
                        @change="updatePartner(data.item)"></b-form-input>
          <span v-else>{{ data.item.Link }}</span>
        </template>
        <template #cell(Delete)="data">
          <b-button variant="danger" @click="deleteItem(data.item.ID)">Delete</b-button>
        </template>
      </b-table>
    </div>
  </b-container>
</template>


<script>
import api from "@/api/api";

export default {
  name: "ShowPartners",
  data() {
    return {
      partners: [],
      editableItem: null,
      fields: [
        {key: "ID", visible:false},
        {key: "Name"},
        {key: "En"},
        {key: "Th"},
        {key: "Vi"},
        {key: "Ms"},
        {key: "Km"},
        {key: "Mm"},
        {key: "Logo", label: "Logo"},
        {key: "Link"},
        {key: "Delete"}
      ],
      apiUrl: process.env.VUE_APP_API_URL
    }
  },
  mounted() {
    api.getPartners().then(response => {
      if (response.data.length === 0) {
        this.partners = []
      } else {
        this.partners = response.data;
      }
    });
  },
  methods: {
    rowClicked(item) {
      this.editableItem = item;
    },

    deleteItem(id){
      api.deletePartner(id)
          .then(()=> {
            this.partners = this.partners.filter(item => item.ID !== id);
          })
    },

    updatePartner(updatedPartner) {
      api.updatePartner(updatedPartner).then(() => {
        console.log('Partner updated');
        this.editableItem = null;
      });
    }
  },

};
</script>

<style scoped lang="scss">
</style>

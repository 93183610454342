<template>
  <b-container fluid>
    <b-row class="my-3">
      <b-col>
        <b-button class="w-100" variant="success" @click="saveSubcategory">Save</b-button>
      </b-col>
    </b-row>
    <b-alert v-if="successAlert" class="text-center" show variant="success">Successfully added!</b-alert>
    <b-row>
      <b-col cols="12">
        <h5 class="mb-3">Category: <span v-if="subcategory.CategoryID!==0">{{subcategory.Category.Title}}</span></h5>
        <b-form-select v-if="subcategory.CategoryID===0" v-model="subcategory.Category">
          <option v-for="category in categories" :key="category.id" :value="category">
            {{ category.Title }}
          </option>
        </b-form-select>
      </b-col>
    </b-row>

    <b-row v-if="subcategory.IconLink">
      <img style="max-width: 100px; height: auto"  :src="urlConstructor(subcategory.IconLink)">
    </b-row>

    <b-row class="mt-5">
      <b-col>
        <b-form-group label="Upload Logo">
          <b-form-file @change="uploadLogo($event.target.files[0])" drop-placeholder="Drop file here..."
                       placeholder="Choose a file or drop it here..."></b-form-file>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-tabs content-class="mt-5">
        <b-tab v-for="tab in tabs" :key="tab.label" :title="tab.label">
          <h4>{{ tab.label }}</h4>

          <b-form-group label="Title">
            <b-form-input v-model="subcategory['Title'+tab.key]" placeholder="Enter title" type="text"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-3" label="Description">
            <b-form-textarea v-model="subcategory['Description'+tab.key]" placeholder="Enter title" type="text"></b-form-textarea>
          </b-form-group>
        </b-tab>
      </b-tabs>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-button class="w-100" variant="success" @click="saveSubcategory">Save</b-button>
      </b-col>
    </b-row>


  </b-container>
</template>

<script>
import EditorWithFilters from "@/components/shared/EditorWithFilters.vue";
import AuthorCard from "@/components/authors/authorCard.vue";
import SubcategorySelector from "@/components/shared/subcategorySelector.vue";
import AuthorModal from "@/components/shared/authorModal.vue";
import LogoSizeSelect from "@/components/shared/logoSizeSelect.vue";
import axios from "axios";
import files from "@/services/files";
import api from "@/api/api";

export default {
  name: "CreateSubcategories",
  components: {LogoSizeSelect, AuthorModal, SubcategorySelector, AuthorCard, EditorWithFilters},
  data() {
    return {
      subcategory: {
        Title:"",
        TitleTh: "",
        TitleVi: "",
        TitleMs: "",
        TitleKm: "",
        TitleMm: "",
        Description: "",
        DescriptionTh: "",
        DescriptionVi: "",
        DescriptionMs: "",
        DescriptionKm: "",
        DescriptionMm: "",
        IconLink: "",
        Order: 0,
        CategoryID: 0,
        Category: {
          id: 0,
        },
      },
      successAlert: false,
      tabs: [
        {
          label: 'English',
          key: ''
        },
        {
          label: 'Thai',
          key: 'Th'
        },
        {
          label: 'Vietnamese',
          key: 'Vi'
        },
        {
          label: 'Malay',
          key: 'Ms'
        },
        {
          label: 'Khmere',
          key: 'Km'
        },
        {
          label: 'Myanmar',
          key: 'Mm'
        },
      ],
      loading: true,
      categories: [],
    }
  },
  methods: {
    handleRouteChange(to, from) {
      this.$router.go(0);
    },
    saveSubcategory () {
      axios.post(`${process.env.VUE_APP_API_URL}/subcategories_new`, this.subcategory, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
          .then(response => {
            this.successAlert = true;
            // this.emptyArticles();
            this.$router.go(0);

            setTimeout(() => {
              this.successAlert = false;
            }, 5000);
          })
          .catch(error => {
            console.error('Error creating article: ', error);
          });
    },
    urlConstructor(url) {
      return process.env.VUE_APP_API_URL + url;
    },

    uploadLogo(file,key) {
      files.createImage(file, "images/resources/logo").then((response) => {
        this.subcategory.IconLink = response.data;
      });
    },

  },
  mounted() {
    api.getCategories().then((res) => {
      this.categories = res.data;
      if (this.$route.params.id) {
        // Загрузка существующей подкатегории
        axios.get(`${process.env.VUE_APP_API_URL}/subcategories_new/${this.$route.params.id}`)
            .then(response => {
              this.subcategory = response.data;
              this.loading = false;
            })
            .catch(error => {
              console.error('There was an error: ', error);
            });
      } else {
        this.loading = false;
      }
    })
  },
  watch: {
    '$route': 'handleRouteChange',
  },
}
</script>


<style lang="scss" scoped>

</style>
<template>
  <div class="box-login mb-6" @keyup.enter="auth">
    <p class="title-form">
      2FA Authorization
    </p>
    <InputMain v-model="codeOtp" :type="`text`" :inputLabel="inputLogin" :src="require('@/assets/icon/vpn_key.svg')"></InputMain>
    <ButtonMain @click="sendOtp" class="mb-2" :button-title="buttonTitle"></ButtonMain>
    <p v-if="failed" style="text-align: center; font-size: 16px; color: red">
      Bad code
    </p>
  </div>
</template>

<script>
import ButtonGoogle from "@/components/shared/ButtonGoogle.vue";
import InputMain from "@/components/shared/InputMain.vue";
import InputCheckbox from "@/components/shared/InputCheckbox.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import serviceAuth from "@/services/auth"
import router from "@/router";

export default {
  name: 'FormOTP',
  components: {ButtonMain, InputCheckbox, InputMain, ButtonGoogle},

  data() {
    return {
      buttonTitle: "Enter",
      inputLogin: "OTP code",
      codeOtp: "",
      failed: false
    }
  },
  methods: {
    sendOtp(){
      serviceAuth.sendOTP(this.codeOtp)
          .then((response)=>{
            if (response === "true") {
              router.push("/")
            } else {
              this.failed = true
            }
          })
    }
  }
}
</script>

<style scoped lang="scss">

.box-login {
  gap: 1rem;

  .link-password-recovery {
    color: #1D1A31;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    text-decoration-line: underline;
    cursor: pointer;
    width: fit-content;
    margin: 0 auto;
  }
}
</style>
<template>
      <b-col>
        <h5>Sources</h5>
         <b-form-group label="Link">
           <b-input v-model="newSource.link"/>
         </b-form-group>
        <b-form-group class="mt-3" label="Description">
          <b-input v-model="newSource.description"/>
        </b-form-group>

        <b-button class="mt-3" variant="primary" @click="addSource">Add Source</b-button>
        <b-table striped hover class=" mt-3" :fields="fields" :items="sources">
          <template v-slot:cell(description)="data">
            {{data.item.description}}
          </template>
          <template v-slot:cell(link)="data">
            {{data.item.link}}
          </template>
          <template v-slot:cell(actions)="data">
            <b-button size="sm" @click="removeSource(data.item)" variant="danger">Delete</b-button>
          </template>
        </b-table>
      </b-col>

</template>

<script>
import index from "vuex";

export default {
  name: "sources",
  props: ['sources'],
  data() {
    return {
      newSource: {
        link: "",
        description: ""
      },
      loading: true,
      fields: [
          index,
        // A column that needs custom formatting
        { key: 'description', label: 'Description' },
        // A virtual column made up from two fields
        { key: 'link', label: 'Link' },
        { key: 'actions', label: 'Actions'}
      ],
    }
  },
  methods: {
    addSource() {
      console.log(this.newSource);
      this.$emit('add-source', this.newSource);
      this.newSource = {
        link: "",
        description: ""
      }
    },
    removeSource(source) {
      this.$emit('remove-source', source);
    }
  }
}
</script>

<style scoped lang="scss">

</style>
<template>
  <b-container class="home text-end">
    <b-button variant="danger" @click="logOut()">Exit</b-button>
<!--    <b-card no-body class="mb-1">-->
<!--      <b-card-header header-tag="header" class="p-1" role="tab">-->
<!--        <b-button block href="#" v-b-toggle.collapse1>Categories</b-button>-->
<!--      </b-card-header>-->
<!--      <b-collapse id="collapse1">-->
<!--        <b-card-body>-->
<!--          <show-categories/>-->
<!--          <add-categories class="mt-5"/>-->
<!--        </b-card-body>-->
<!--      </b-collapse>-->
<!--    </b-card>-->

<!--    <b-card no-body class="mb-1">-->
<!--      <b-card-header header-tag="header" class="p-1" role="tab">-->
<!--        <b-button block href="#" v-b-toggle.collapse2>SubCategories</b-button>-->
<!--      </b-card-header>-->
<!--      <b-collapse id="collapse2">-->
<!--        <b-card-body>-->
<!--          <show-subcategories/>-->
<!--          <add-subcategories class="mt-5"/>-->
<!--        </b-card-body>-->
<!--      </b-collapse>-->
<!--    </b-card>-->

<!--    <b-card no-body class="mb-1">-->
<!--      <b-card-header header-tag="header" class="p-1" role="tab">-->
<!--        <b-button block href="#" v-b-toggle.collapse3>Partners</b-button>-->
<!--      </b-card-header>-->
<!--      <b-collapse id="collapse3">-->
<!--        <b-card-body>-->
<!--          <show-partners/>-->
<!--          <add-partners/>-->
<!--        </b-card-body>-->
<!--      </b-collapse>-->
<!--    </b-card>-->
  </b-container>
</template>

<script>
import ShowCategories from "@/components/ShowCategories.vue";
import AddCategories from "@/components/AddCategories.vue";
import ShowSubcategories from "@/components/ShowSubcategories.vue";
import AddSubcategories from "@/components/AddSubcategories.vue";
import ShowPartners from "@/components/ShowPartners.vue";
import AddPartners from "@/components/AddPartners.vue";
import Cookies from "js-cookie";

export default {
  name: 'HomeView',
  components: {
    AddPartners,
    AddSubcategories,
    ShowSubcategories,
    AddCategories,
    ShowCategories,
    ShowPartners
  },
  methods: {
    logOut() {
      Cookies.remove('token')
      Cookies.remove('refreshToken')
      window.location.href = "/"
    }
  }
}
</script>

// api.js
import axios from 'axios';
import Cookies from "js-cookie";

const API_URL = process.env.VUE_APP_API_URL;

axios.defaults.baseURL = API_URL;

axios.defaults.headers.common['Authorization'] = Cookies.get("token");
axios.defaults.headers.common['Refresh-Token'] = Cookies.get("refreshToken");

axios.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                axios.defaults.headers.common['Authorization'] = Cookies.get("token");
                axios.defaults.headers.common['Refresh-Token'] = Cookies.get("refreshToken");
                let response = await axios.get('/user/auth/refresh');

                Cookies.set('token', response.data.token, { expires: new Date(new Date().getTime() + 15 * 60 * 1000) });
                Cookies.set('refreshToken', response.data.refreshToken, { expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000) });

                originalRequest.headers['Authorization'] = response.data.token;

                let decodedToken = jwtDecode(response.data.token)
                store.commit("setUser", decodedToken)

                return axios(originalRequest);
            } catch (refreshError) {
                if (router.currentRoute.path !== '/auth') {
                    router.push('/auth');
                }
            }
        }

        return Promise.reject(error);
    }
);

export default {

    //Category
    getCategories() {
        return axios.get(`${API_URL}/categories`);
    },
    addCategory(title, titleTh, titleVi, titleMs, titleKm, icon) {
        const formData = new FormData();
        formData.append('title', title);
        formData.append('titleTh', titleTh);
        formData.append('titleVi', titleVi);
        formData.append('titleMs', titleMs);
        formData.append('titleKm', titleKm);
        formData.append('icon', icon);

        return axios.post(`${API_URL}/categories`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },


    updateCategory(category) {
        return axios.put(`${API_URL}/categories/${category.ID}`, category);
    },



    //SubCategory
    getSubcategories() {
        return axios.get(`${API_URL}/subcategories`);
    },
    addSubcategory(categoryData) {
        const formData = new FormData();
        Object.keys(categoryData).forEach(key => {
            formData.append(key, categoryData[key]);
        });
        return axios.post(`${API_URL}/subcategories`, formData);
    },

    //Partner

    getPartners() {
        return axios.get(`${API_URL}/partners`);
    },

    addPartner(partnerData) {
        const formData = new FormData();
        Object.keys(partnerData).forEach(key => {
            formData.append(key, partnerData[key]);
        });
        return axios.post(`${API_URL}/partners`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    updatePartner(partnerData) {
        return axios.put(`${API_URL}/partners/${partnerData.ID}`, partnerData);
    },

    deletePartner(id) {
        return axios.delete(`${API_URL}/partners/`+id)
    },

    getCategoriesFull(){
        return axios.get(`${API_URL}/categories`)
    },

    //Author
    createAuthor(authorData) {
       return  axios.post(`${API_URL}/authors`, authorData)
    },

    getAuthors() {
        return axios.get(`${API_URL}/authors/list?page_size=1000`,)
    },

    getAuthorById(id) {
        return axios.get(`${API_URL}/authors/`+id)
    },

    deleteAuthor(id) {
        return axios.delete(`${API_URL}/authors/`+id)
    },

    //TaskForce
    createTaskForce(taskForceData) {
        return  axios.post(`${API_URL}/task_forces`, taskForceData)
    },

    getTaskForces() {
      return axios.get(`${API_URL}/task_forces/list?page_size=1000`)
    },

    getTaskForceById(id) {
        return axios.get(`${API_URL}/task_forces/`+id)
    },

    deleteTaskForce(id) {
        return axios.delete(`${API_URL}/task_forces/`+id)
    },

    //Videos
    getVideos() {
        return axios.get(`${API_URL}/videos/list?page_size=1000`)
    },


    //Posters
    getPosters() {
        return axios.get(`${API_URL}/posters/list?page_size=1000`)
    },

    //Files
    uploadImage(data){
        return axios.post(`${API_URL}/file/upload`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': Cookies.get("token"),
            }
        });
    },

    //Category Writers & Reviewers
    saveWritersReviewers(data){
        return axios.post(`${API_URL}/writers_reviewers`, data);
    },

    getWritersReviewers(){
        return axios.get(`${API_URL}/writers_reviewers/list?page_size=1000`);
    },

    deleteWritersReviewers(id){
        return axios.delete(`${API_URL}/writers_reviewers/`+id);
    },

    auth(data) {
        return axios.post('/user/auth', data)
    },

    refreshJWT () {
        return axios.get("/user/auth/refresh")
    },

    //Users managment
    getUsers() {
        return axios.get(`/users`)
    },

    createUser(data) {
        return axios.post('/user', data)
    },

    deleteUser(data) {
        return axios.delete(`/user/${data}`)
    }

};

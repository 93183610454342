<template>
  <div class="did-floating-label-content">
    <input class="did-floating-input" v-model="inputValue" :type="type" placeholder=" ">
    <label class="did-floating-label">{{ inputLabel }}</label>
    <img alt="" :src="src">
  </div>
</template>

<script>
export default {
  name: 'InputMain',
  props: ['inputLabel', 'src', 'type', 'value'],
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },
}
</script>


<style scoped lang="scss">

@import "@/assets/styles/color.scss";


.did-floating-label-content {
  position:relative;
  .did-floating-label {
    color: $icons-and-minor;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    pointer-events:none;
    top: 50%;
    transform: translate(0, -50%);
    left: 3rem;
    padding:0 5px;
    background:#fff;
    transition:0.2s ease all;
  }

  .did-floating-input{
    font-size: 16px;
    display: block;
    width: 100%;
    height: 3rem;
    padding: 0 1.25rem 0 3.25rem;
    background: $white;
    color: $icons-and-minor;
    border: 1px solid $light-blue-stroke;
    border-radius: 0.5rem;
    box-sizing: border-box;
    &:focus{
      outline:none;
      ~ .did-floating-label{
        top: 0;
        transform: translate(0, -50%);
        font-size:13px;
      }
    }
  }
  .did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
    top: 0;
    transform: translate(0, -50%);
    font-size:13px;
  }
  img{
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translate(5%, -50%);
  }
}
</style>
<template>
  <div>
    <b-alert v-if="successAdd" class="mt-3 text-center" show variant="success">Successfully
      <span v-if="!this.$route.params.id">added</span>
      <span v-else>updated</span>
      !
    </b-alert>
    <b-row>
      <b-tabs class="my-3">
        <b-tab class="mt-3" title="English">
          <b-form-group label="Title">
            <b-form-input v-model="taskForce.title"></b-form-input>
          </b-form-group>
        </b-tab>
        <b-tab class="mt-3" title="Thai">
          <b-form-group label="Title">
            <b-form-input v-model="taskForce.titleTh"></b-form-input>
          </b-form-group>
        </b-tab>
        <b-tab class="mt-3" title="Vietnamese">
          <b-form-group label="Title">
            <b-form-input v-model="taskForce.titleVi"></b-form-input>
          </b-form-group>
        </b-tab>
        <b-tab class="mt-3" title="Malay">
          <b-form-group label="Title">
            <b-form-input v-model="taskForce.titleMs"></b-form-input>
          </b-form-group>
        </b-tab>
        <b-tab class="mt-3" title="Khmer">
          <b-form-group label="Title">
            <b-form-input v-model="taskForce.titleKm"></b-form-input>
          </b-form-group>
        </b-tab>
        <b-tab class="mt-3" title="Myanmar">
          <b-form-group label="Title">
            <b-form-input v-model="taskForce.titleMm"></b-form-input>
          </b-form-group>
        </b-tab>
      </b-tabs>
    </b-row>
    <hr>

    <b-row>
      <b-col>
        <h5>
          Authors:
        </h5>
      </b-col>
      <b-col class="text-end">
        <b-button v-b-modal.authors-modal variant="info">Add</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="taskForce.authors.length === 0" class="d-flex" style="min-height: 300px">
        <p class="text-center m-auto">No authors selected</p>
      </b-col>
      <b-col v-else class="d-flex">
        <author-card
            v-for="author in taskForce.authors"
            :author="author"
            :lite="true"
            :removeIcon="true"
            :order="true"
            class="author-card"
            @removeAuthor="taskForce.authors.splice(taskForce.authors.indexOf(author), 1)"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-button @click="saveTaskForce" class="w-100 my-3" variant="success">Save</b-button>
      </b-col>
    </b-row>

    <author-modal
        :authors="authors"
        @selectAuthor="selectAuthor"/>
  </div>
</template>

<script>
import AuthorModal from "@/components/shared/authorModal.vue";
import api from "@/api/api";
import AuthorCard from "@/components/authors/authorCard.vue";

export default {
  name: "CreateTaskForces",
  components: {AuthorCard, AuthorModal},
  data() {
    return {
      taskForce: {
        title: '',
        titleTh: '',
        titleVi: '',
        titleMs: '',
        titleKm: '',
        titleMm: '',
        authors: []
      },
      authors: [],
      successAdd: false,
    }
  },
  methods: {
    selectAuthor(author) {
      this.$bvModal.hide('authors-modal')
      if (this.taskForce.authors.find(item => item.id === author.id)) {
        return;
      }
      this.taskForce.authors.push(author)
    },
    saveTaskForce() {
      for (let i = 0; i < this.taskForce.authors.length; i++) {
        this.taskForce.authors[i].order = Number(this.taskForce.authors[i].order);
      }
      api.createTaskForce(this.taskForce).then((response) => {
        this.taskForce = {
          title: '',
          titleTh: '',
          titleVi: '',
          titleMs: '',
          titleKm: '',
          titleMm: '',
          authors: []
        }
        this.successAdd = true;
        setTimeout(() => {
          this.successAdd = false;
        }, 5000);
        if (this.$route.params.id) {
          setTimeout(() => {
            this.$router.push('/task_forces')
          }, 2000)
        }
      })
    },
  },

  mounted() {
    api.getAuthors().then((response) => {
      this.authors = response.data;
    });

    if (this.$route.params.id) {
      api.getTaskForceById(this.$route.params.id)
          .then((response) => {
            this.taskForce = response.data;
            //order authors by order
            this.taskForce.authors.sort((a, b) => (a.order > b.order) ? 1 : -1)

          })
    }
  }
}
</script>


<style lang="scss" scoped>

.d-flex {
  flex-wrap: wrap;
  gap: 20px;

  .author-card {
    flex: 0 0 calc(25% - 20px);
    max-width: calc(25% - 20px);
  }
}

</style>
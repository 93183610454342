<template>
  <b-modal id="posters-modal"
           hide-footer
           hide-header-close
           size="lg"
  >
    <template #modal-title>
      Select Poster
    </template>

    <b-row>
      <b-col cols="12">
        <b-form-input v-model="search"
                      class="mb-3"
                      placeholder="Search posters by title..."
                      type="text"
        ></b-form-input>
      </b-col>
      <b-col v-for="poster in filteredPosters"
             :key="poster.id"
             class="mb-3"
             cols="6"
      >
        <poster-card :poster="poster"
                    :lite="true"

                    class="m-auto"
                    @select-poster="$emit('select-poster', $event)"
        />
      </b-col>
    </b-row>

  </b-modal>
</template>

<script>
import PosterCard from "@/components/posters/posterCard.vue";

export default {
  components: {PosterCard},
  props: ["posters"],
  name: "posterModal",
  data() {
    return {
      search: ''
    };
  },
  computed: {
    filteredPosters() {
      return this.posters.filter(poster => poster.title.toLowerCase().includes(this.search.toLowerCase()));
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>

  <b-row class="mt-3">
    <b-col v-for="resource in resources" :key="resource.id" class="mb-4" md="4" sm="6" xs="12">
      <b-card :img-src="webinar?getImageUrl(resource.banner):getImageUrl(resource.logo)"
              :title="resource.title"
              img-alt="Image"
              img-top
              style=""
              tag="article">
        <b-card-text v-if="resource.subcategory">
          {{ resource.subcategory.title }}
        </b-card-text>
        <div class="d-flex">
          <b-button v-if="!nonEdit" class="m-auto" variant="primary" @click="openEdit(resource.id)">Edit</b-button>
          <b-button class="m-auto" variant="danger" @click="deleteResource(resource.id)">Delete</b-button>
        </div>

      </b-card>
    </b-col>
  </b-row>

</template>

<script>
export default {
  name: "showResources",
  props: ['resources', 'nonEdit', 'webinar'],
  methods: {
    openEdit(id) {
      this.$emit('open-edit', id);
    },
    deleteResource(id) {
      this.$emit('delete-resource', id);
    },
    getImageUrl(imageName) {
      if (imageName) {
        return process.env.VUE_APP_API_URL + imageName;
      } else {
        return require("@/assets/icon/no-avatar.png");
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
<template>
  <button class="btn-secondary">
    <img alt="" src="@/assets/icon/devicon_google.svg">
    Войти с помощью Google+
  </button>
</template>

<script>
export default {
  name: 'ButtonGoogle',
}
</script>


<style scoped lang="scss">
.btn-secondary {
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  border-radius: 8px;
  background: #FFF;
  padding: 0.875rem 2rem;
  color: #1D1A31;
  border: 1px solid #D4E1EE;
  outline: none;
  cursor: pointer;
  position: relative;
  img {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translate(0, -50%);
  }
}
</style>
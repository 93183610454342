import { render, staticRenderFns } from "./CreateNews.vue?vue&type=template&id=03f8e190&scoped=true&"
import script from "./CreateNews.vue?vue&type=script&lang=js&"
export * from "./CreateNews.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03f8e190",
  null
  
)

export default component.exports
<template>
  <b-container fluid>
    <b-row class="my-3">
      <b-col>
        <b-button class="w-100" variant="success" @click="saveArticle">Save</b-button>
      </b-col>
    </b-row>
    <b-alert v-if="successAlert" class="text-center" show variant="success">Successfully added!</b-alert>
    <b-row>
      <b-col cols="12">
        <h5 class="mb-3">Category/SubCategory</h5>
        <subcategory-selector :selected-subcategory-init="article.subcategory" @subcategory-change="selectSubCategory"/>
      </b-col>
      <b-col class="mt-3">
        <h5 class="mb-3">Authors</h5>
        <b-row>
          <b-col class="border p-3">
            <h6>Main author</h6>
            <b-button v-b-modal.authors-modal class="m-auto" variant="info" @click="isMainAuthSelecting=true">
              Select Main Author
            </b-button>

            <author-card v-if="article.mainAuthor && article.mainAuthor.icon"
                         :author="article.mainAuthor"
                         :full-width="true"
                         :lite="true"
                         class="m-auto mt-3"
            />

          </b-col>
          <b-col class="border p-3">
            <h6>Co-authors</h6>
            <b-button v-b-modal.authors-modal @click="isMainAuthSelecting=false">
              Select Co-Author
            </b-button>

            <author-card
                v-for="author in article.authors"
                :key="author.id"
                :author="author"
                :full-width="true"
                :lite="true"
                :remove-icon="true"
                :without-avatar="true"
                class="mt-3"
                @removeAuthor="removeCoAuthor(author)"
            />

          </b-col>
        </b-row>

        <b-row class="mt-3">
          <h5 class="mb-3">Resources</h5>
          <b-col class="border p-3 position-relative">
            <h6>Poster</h6>
            <b-button v-b-modal.posters-modal class="m-auto" variant="success">
              Select Poster
            </b-button>
            <b-button v-if="article.posterId" class="ms-1" variant="danger"
                      @click="article.posterId = null; article.poster = null">
              Remove Poster
            </b-button>

            <div v-if="article.posterId" class="mt-3">
              <h5>{{ article.poster.title }}</h5>
              <div>
                <img :src="urlConstructor(article.poster.logo)" style="max-width: 100%">
              </div>
            </div>
          </b-col>

          <b-col class="border p-3">
            <h6>Video</h6>
            <b-button v-b-modal.videos-modal class="m-auto" variant="success">
              Select Video
            </b-button>
            <b-button v-if="article.videoId" class="ms-1" variant="danger"
                      @click="article.videoId = null; article.video = null">
              Remove Video
            </b-button>

            <iframe v-if="article.videoId" :src="convertYouTubeUrl(article.video.video)"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen class="mt-3"
                    frameborder="0" height="315"
                    title="YouTube video player"
                    width="560"></iframe>

          </b-col>
        </b-row>

        <b-row class="my-5">
          <sources
              :sources="article.sources"

              @add-source="addSource"
              @remove-source="removeSource"/>

          <reviewers
              :authorCategoryReviewers="article.authorCategoryReviewers"
              :authors="authors"
              :reviewers_categories="reviewersCategories"
              @add-author-to-category="handleAddAuthorToCategory"
              @remove-category="handleRemoveCategory"
          />


        </b-row>


      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="d-flex">
          <h5 class="m-auto ms-0">Related Articles:</h5>
          <b-button v-b-modal.articles-modal size="sm" variant="info">Add</b-button>
        </div>
        <show-resources
            :nonEdit="true"
            :resources="article.relatedArticles"
            @delete-resource="handlerDeleteRelatedArticle"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="d-flex">
          <h5 class="m-auto ms-0">Related Quizzes:</h5>
          <b-button v-b-modal.quizzes-modal size="sm" variant="info">Add</b-button>
        </div>
        <b-table v-if="article.quizzes.length !== 0" :items="article.quizzes"
                 :fields="['name', 'action']"
        >
          <template v-slot:cell(action)="data">
            <b-button variant="primary" @click="editQuiz(data.item.id)">Edit</b-button>
            <b-button variant="danger" class="ms-3" @click="deleteQuiz(data.item.id)">Delete</b-button>
          </template>
        </b-table>
        <p v-else>No quizzes</p>
      </b-col>

    </b-row>

    <b-row class="mt-5">
      <b-tabs content-class="mt-5">
        <b-tab v-for="tab in tabs" :key="tab.label" :title="tab.label">
          <h4>{{ tab.label }}</h4>

          <b-row>
            <b-col>
              <img v-if="article['logo'+tab.key]" :src="urlConstructor(article['logo'+tab.key])" class="w-100">
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col>
              <b-form-group label="Upload Logo">
                <b-form-file drop-placeholder="Drop file here..." placeholder="Choose a file or drop it here..."
                             @change="uploadLogo($event.target.files[0], tab.key)"></b-form-file>
                <logo-size-select class="mt-3" @size-change="selectLogoSize"/>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group class="my-3" label="Title">
            <b-form-input v-model="article['title'+tab.key]" placeholder="Enter title" type="text"></b-form-input>
          </b-form-group>
          <hr>
          <h4>SEO</h4>

          <b-form-group class="mt-3" label="Title">
            <b-form-input v-model="article['titleSeo'+tab.key]" placeholder="Enter title" type="text"></b-form-input>
          </b-form-group>

          <b-form-group class="mt-3" label="Keywords">
            <b-form-input v-model="article['keywords'+tab.key]" placeholder="Enter keywords" type="text"></b-form-input>
          </b-form-group>
          <b-form-group class="mt-3" label="Description">
            <b-form-textarea v-model="article['descriptionSeo'+tab.key]" placeholder="Enter Description for SEO"
                             type="text"></b-form-textarea>
          </b-form-group>
          <hr>

          <editor-with-filters v-if="!loading" :init-html="article['body'+tab.key]" :tab="tab" class="mt-3"
                               @input-editor="updateInput"/>
        </b-tab>
      </b-tabs>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-button class="w-100" variant="success" @click="saveArticle">Save</b-button>
      </b-col>
    </b-row>

    <!--Modal-->
    <author-modal
        :authors="authors"
        @selectAuthor="selectAuthor"/>

    <video-modal
        :videos="videos"
        @select-video="selectVideo"/>

    <poster-modal
        :posters="posters"
        @select-poster="selectPoster"/>

    <article-modal
        :articles="articles"
        @select-article="handlerAddRelatedArticle"/>

    <quizzes-modal
        :quizzes="quizzes"
        @select-quiz="handlerAddRelatedQuiz"/>


  </b-container>
</template>

<script>
import EditorWithFilters from "@/components/shared/EditorWithFilters.vue";
import SubcategorySelector from "@/components/shared/subcategorySelector.vue";
import LogoSizeSelect from "@/components/shared/logoSizeSelect.vue";
import axios from "axios";
import AuthorModal from "@/components/shared/authorModal.vue";
import api from "@/api/api";
import AuthorCard from "@/components/authors/authorCard.vue";
import files from "@/services/files";
import VideoModal from "@/components/shared/videoModal.vue";
import PosterModal from "@/components/shared/posterModal.vue";
import Sources from "@/components/articles/sources.vue";
import Reviewers from "@/components/articles/reviewers.vue";
import ShowResources from "@/components/shared/showResources.vue";
import ArticleModal from "@/components/shared/articleModal.vue";
import QuizzesModal from "@/components/shared/quizzesModal.vue";

export default {
  name: 'CreateArticles',
  components: {
    QuizzesModal,
    ArticleModal,
    ShowResources,
    Reviewers,
    Sources,
    PosterModal, VideoModal, AuthorCard, AuthorModal, LogoSizeSelect, SubcategorySelector, EditorWithFilters
  },
  data: () => ({
    loading: true,
    successAlert: false,
    htmlInput: false,
    article: {
      id: null,
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
      relatedArticles: [],
      authorCategoryReviewers: [],
      title: "",
      body: "",
      titleTh: "",
      bodyTh: "",
      titleVi: "",
      bodyVi: "",
      titleMs: "",
      bodyMs: "",
      titleKm: "",
      bodyKm: "",
      titleMm: "",
      bodyMm: "",
      logo: "",
      logoTh: "",
      logoKm: "",
      logoVi: "",
      logoMs: "",
      logoMm: "",
      subcategoryID: null,
      resourceLogoSizeID: 4,
      video: null,
      videoId: null,
      poster: null,
      posterId: null,
      type: "1",
      mainAuthor: null,
      mainAuthorID: null,
      sources: [],
      authors: [],
      keywords: "",
      keywordsTh: "",
      keywordsVi: "",
      keywordsMs: "",
      keywordsKm: "",
      kywordsMm: "",
      quizzes: [],
    },
    isMainAuthSelecting: false,
    tabs: [
      {
        label: 'English',
        key: ''
      },
      {
        label: 'Thai',
        key: 'Th'
      },
      {
        label: 'Vietnamese',
        key: 'Vi'
      },
      {
        label: 'Malay',
        key: 'Ms'
      },
      {
        label: 'Khmere',
        key: 'Km'
      },
      {
        label: 'Myanmar',
        key: 'Mm'
      }
    ],
    authors: [],
    videos: [],
    posters: [],
    articles: [],
    reviewersCategories: [],
    quizzes: [],
  }),
  methods: {
    handleRouteChange(to, from) {
      this.$router.go(0);
    },
    updateInput(data) {
      this.article["body" + data.key] = data.html
    },

    selectSubCategory(subcategory) {
      this.article.subcategoryID = subcategory.ID
      this.article.subcategory = subcategory
    },
    selectLogoSize(size) {
      this.article.resourceLogoSizeID = size
    },
    urlConstructor(url) {
      return process.env.VUE_APP_API_URL + url;
    },

    //PostForm request
    saveArticle() {
      axios.post(`${process.env.VUE_APP_API_URL}/articles`, this.article, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
          .then(response => {
            console.log('Article created: ', response.data);
            this.successAlert = true;
            this.emptyArticles();
            this.$router.go(0);

            setTimeout(() => {
              this.successAlert = false;
            }, 5000);
          })
          .catch(error => {
            console.error('Error creating article: ', error);
          });
    },

    emptyArticles() {
      this.article = {
        ID: null,
        CreatedAt: "",
        UpdatedAt: "",
        DeletedAt: null,
        title: "",
        body: "",
        titleTh: "",
        bodyTh: "",
        titleVi: "",
        bodyVi: "",
        titleMs: "",
        bodyMs: "",
        titleKm: "",
        bodyKm: "",
        titleMm: "",
        bodyMm: "",
        logo: null,
        subcategoryID: null,
        resourceLogoSizeID: "4",
        type: "1",
        mainAuthor: null,
        mainAuthorID: null,
        authors: []
      }
    },
    selectAuthor(author) {
      this.$bvModal.hide('authors-modal')
      if (this.isMainAuthSelecting) {
        if (this.article.mainAuthor && this.article.mainAuthor.id === author.id) {
          return;
        }
        this.article.mainAuthor = author;
        this.article.mainAuthorID = author.id;
      } else {
        if (this.article.authors.find(item => item.id === author.id)) {
          return;
        }
        this.article.authors.push(author);
      }
    },
    selectPoster(poster) {
      this.$bvModal.hide('posters-modal')
      this.article.poster = poster;
      this.article.posterId = poster.id;
    },
    selectVideo(video) {
      this.$bvModal.hide('videos-modal')
      this.article.video = video;
      this.article.videoId = video.id;
    },
    removeCoAuthor(author) {
      this.article.authors = this.article.authors.filter(item => item.id !== author.id);
    },
    uploadLogo(file, key) {
      files.createImage(file, "images/resources/logo").then((response) => {
        this.article['logo' + key] = response.data;
      });
    },
    convertYouTubeUrl(url) {
      return url.replace("watch?v=", "embed/");
    },
    addSource(source) {
      console.log(source)
      this.article.sources.push(source)
    },
    removeSource(source) {
      this.article.sources = this.article.sources.filter(item => item.link !== source.link);
    },

    handleAddAuthorToCategory(data) {
      this.article.authorCategoryReviewers.push({
        author: data.author,
        reviewersCategory: data.category,
        authorId: data.author.id,
        reviewersCategoryId: data.category.id,
      });
    },

    // Обработка удаления категории
    handleRemoveCategory(categoryId) {

      this.article.authorCategoryReviewers = this.article.authorCategoryReviewers.filter(item => item.reviewersCategoryId !== categoryId);
    },

    handlerAddRelatedArticle(article) {
      //close modal articles-modal
      this.$bvModal.hide('articles-modal')

      //check if article already exists
      if (this.article.relatedArticles.find(item => item.id === article.id)) {
        return;
      }
      this.article.relatedArticles.push(article);
    },

    handlerDeleteRelatedArticle(articleId) {
      this.article.relatedArticles = this.article.relatedArticles.filter(item => item.id !== articleId);
    },

    handlerAddRelatedQuiz(quiz) {
      //close modal articles-modal
      this.$bvModal.hide('quizzes-modal')

      //check if article already exists
      if (this.article.quizzes.find(item => item.id === quiz.id)) {
        return;
      }
      this.article.quizzes.push(quiz);
    },

    editQuiz(id) {
      window.open(this.$router.resolve(`/quiz/create/${id}`).href, '_blank');
    },

    //delete from array
    deleteQuiz(id) {
      this.article.quizzes = this.article.quizzes.filter(item => item.id !== id);
    }


  },
  computed: {
    rawHtml: {
      get() {
        return this.editor ? this.editor.getHTML() : '';
      },
      set(value) {
        if (this.editor) this.editor.commands.setContent(value);
      }
    }
  },
  mounted() {
    if (this.$route.params.id) {
      axios.get(`${process.env.VUE_APP_API_URL}/articles/${this.$route.params.id}`)
          .then(response => {
            this.article = response.data;
            // this.article.logo = null;
            this.loading = false;
          })
          .catch(error => {
            console.error('There was an error: ', error);
          });
    } else {
      this.loading = false;
    }
    api.getAuthors().then((response) => {
      this.authors = response.data;
    });
    api.getVideos().then((response) => {
      this.videos = response.data;
    });

    api.getPosters().then((response) => {
      this.posters = response.data;
    });
    api.getWritersReviewers().then((response) => {
      this.reviewersCategories = response.data;
    });

    axios.get(`${process.env.VUE_APP_API_URL}/articles/list`, {
      params: {
        page: 1,
        page_size: 1000,
      }
    })
        .then(response => {
          this.articles = response.data;
        })
        .catch(error => {
          console.error('Error fetching articles: ', error);
        });

    axios.get(`${process.env.VUE_APP_API_URL}/quiz`,{
      params: {
        page: 1,
        page_size: 1000
      }
    })
        .then(response => {
          this.quizzes = response.data;
        })
        .catch(error => {
          console.error('Error fetching quizzes: ', error);
        });

  },
  watch: {
    '$route': 'handleRouteChange',
  },
}
</script>

<style lang="scss" scoped>
.btn {
  width: auto;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.btn-save {
  @extend .btn;
  background: #71e36d;
}
</style>

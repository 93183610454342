<template>
  <div>

    <b-button class="mb-3 w-100" type="submit" variant="success" @click="savePublication()">Submit</b-button>

    <b-form-group label="Title">
      <b-form-input v-model="publication.title" type="text"></b-form-input>
    </b-form-group>

    <editor-with-filters v-if="!loading" :tab="'en'" :init-html="publication.description" class="mt-3"
                         @input-editor="updateInput"/>

    <b-form-group class="mt-3" label="File">
      <b-form-file ref="fileInput" @change="uploadFile($event.target.files[0])"></b-form-file>
    </b-form-group>

    <b-row class="mt-3">
      <b-col class="border p-3">
        <h6>Authors</h6>
        <b-button v-b-modal.authors-modal>
          Select Authors
        </b-button>

        <author-card
            v-for="author in publication.authors"
            :key="author.id"
            :author="author"
            :full-width="true"
            :lite="true"
            :remove-icon="true"
            :without-avatar="true"
            class="mt-3"
            @removeAuthor="removeAuthor(author)"
        />

      </b-col>
    </b-row>

    <b-row v-if="publication.banner" class="mt-3">
      <b-col>
        <img :src="urlConstructor(publication.banner)"  style="max-height: 300px">
      </b-col>
    </b-row>

    <b-form-group class="mt-3" label="Banner">
      <b-form-file ref="fileInput" @change="onFileChange($event.target.files[0])"></b-form-file>
    </b-form-group>

    <b-button class="mt-3 w-100" type="submit" variant="success" @click="savePublication()">Submit</b-button>

    <author-modal
        :authors="authors"
        @selectAuthor="selectAuthor"/>


  </div>

</template>

<script>
import files from "@/services/files";
import axios from "axios";
import AuthorCard from "@/components/authors/authorCard.vue";
import api from "@/api/api";
import AuthorModal from "@/components/shared/authorModal.vue";
import EditorWithFilters from "@/components/shared/EditorWithFilters.vue";

export default {
  name: "CreatePublication",
  components: {EditorWithFilters, AuthorModal, AuthorCard},
  data() {
    return {
      publication: {
        title: "",
        description: "",
        link: "",
        banner: "",
        authors: [],
      },
      authors: [],
      loading: true,
    }
  },
  methods:{
    urlConstructor(url) {
      return process.env.VUE_APP_API_URL + url;
    },

    savePublication() {
      axios.post(`${process.env.VUE_APP_API_URL}/publications`, this.publication, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
          .then(response => {
            this.$router.go(0);

            setTimeout(() => {
              this.successAlert = false;
            }, 5000);
          })
          .catch(error => {
            console.error('Error creating article: ', error);
          });

    },

    removeAuthor(author) {
      this.publication.authors = this.publication.authors.filter(item => item.id !== author.id);
    },

    onFileChange(file) {
      files.createImage(file, "images/prof/publications").then((response) => {
        this.publication.banner = response.data;
      });
    },

    uploadFile(file, lang) {
      files.createImage(file, "images/resources/publication").then((response) => {
        this.publication.link = response.data;
      });
    },

    selectAuthor(author) {
      this.$bvModal.hide('authors-modal')
      if (this.publication.authors.find(item => item.id === author.id)) {
        return;
      }
      this.publication.authors.push(author);
    },

    updateInput(data) {
      this.publication.description = data.html;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      axios.get(`${process.env.VUE_APP_API_URL}/publications/${this.$route.params.id}`)
          .then(response => {
            this.publication = response.data;
            this.loading = false;
          })
          .catch(error => {
            console.error('There was an error: ', error);
          });
    } else  {
      this.loading = false;
    }

    api.getAuthors().then((response) => {
      this.authors = response.data;
    });
  }
}
</script>

<style scoped lang="scss">

</style>
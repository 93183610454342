<template>

  <div>
    <button :class="{'btn-danger': red , 'btn': red}" type="button" @click="openModal">
      {{buttonTitle}}
    </button>

    <div class="modal" v-if="isModalOpen">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Are you sure you want to delete the user?</h5>
          </div>
          <div class="modal-body d-flex gap-3">
            <button-main @click="closeModal" :button-title="'Close'"/>
            <button-secondary :button-title="'Delete'" :colorButton="red"/>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { createPopper } from '@popperjs/core';
import ButtonMain from "@/components/shared/ButtonMain.vue";
import ButtonSecondary from "@/components/shared/ButtonSecondary.vue";
export default {
  name: 'ModalDelete',
  components: {ButtonSecondary, ButtonMain},
  props: {
    buttonTitle: String
  },
  data() {
    return {
      isModalOpen: false,
      red: true,
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
}
</script>

<style scoped lang="scss">
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal-dialog {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.modal-content {
  padding: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
}

.modal-title {
  font-size: 1.25rem;
  margin: 0;
}

.close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
  padding: 0;
}
</style>
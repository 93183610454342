<template>
  <div v-if="editor">
<!--    <BubbleMenu v-if="editor" :editor="editor" class="bubble-menu">-->
<!--      <b-button size="sm" variant="info" :class="{ 'is-active': editor.isActive('orderedList') }"-->
<!--              @click="editor.chain().focus().toggleBulletList().run()">-->
<!--        Bullets-->
<!--      </b-button>-->
<!--    </BubbleMenu>-->
    <div class="filters-group mb-4">
      <div class="form-floating d-flex">
<!--        <select-->
<!--            id="floatingSelect"-->
<!--            aria-label="Floating label select example"-->
<!--            class="form-select my-auto"-->
<!--            @change="handleSelectChange($event)"-->
<!--        >-->
<!--          <option :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }" value="4">title</option>-->
<!--          <option :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }" value="5">subtitle</option>-->
<!--          <option :class="{ 'is-active': editor.isActive('paragraph') }" selected value="6">text</option>-->
<!--        </select>-->
        <b-button @click="editor.chain().focus().toggleHeading({level: 4}).run();" class="mx-1">title</b-button>
        <b-button @click="editor.chain().focus().toggleHeading({level: 5}).run();" class="mx-1">subtitle</b-button>
        <b-button @click="editor.chain().focus().setParagraph().run();" class="mx-1">text</b-button>
      </div>
      <input
          :value="editor.getAttributes('textStyle').color"
          class="my-auto"
          type="color"
          @input="editor.chain().focus().setColor($event.target.value).run()"
      >
      <div class="editor-group">
        <button :class="{ 'is-active': editor.isActive('bold') }" @click="editor.chain().focus().toggleBold().run()">
          <img alt="" src="@/assets/icon/icons-editor/format_bold_FILL0_wght400_GRAD0_opsz24.svg">
        </button>
        <button :class="{ 'is-active': editor.isActive('italic') }"
                @click="editor.chain().focus().toggleItalic().run()">
          <img alt="" src="@/assets/icon/icons-editor/format_italic_FILL0_wght400_GRAD0_opsz24.svg">
        </button>
        <button :class="{ 'is-active': editor.isActive('strike') }"
                @click="editor.chain().focus().toggleStrike().run()">
          <img alt="" src="@/assets/icon/icons-editor/format_strikethrough_FILL0_wght400_GRAD0_opsz24.svg">
        </button>
        <button :class="{ 'is-active': editor.isActive('underline') }"
                @click="editor.chain().focus().toggleUnderline().run()">
          <img alt="" src="@/assets/icon/icons-editor/format_underlined_FILL0_wght400_GRAD0_opsz24.svg">
        </button>
        <button :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
                @click="editor.chain().focus().setTextAlign('left').run()">
          <img alt="" src="@/assets/icon/icons-editor/format_align_left_FILL0_wght400_GRAD0_opsz24.svg">
        </button>
        <button :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
                @click="editor.chain().focus().setTextAlign('right').run()">
          <img alt="" src="@/assets/icon/icons-editor/format_align_right_FILL0_wght400_GRAD0_opsz24.svg">
        </button>
        <button :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
                @click="editor.chain().focus().setTextAlign('center').run()">
          <img alt="" src="@/assets/icon/icons-editor/format_align_center_FILL0_wght400_GRAD0_opsz24.svg">
        </button>
        <button :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
                @click="editor.chain().focus().setTextAlign('justify').run()">
          <img alt="" src="@/assets/icon/icons-editor/format_align_justify_FILL0_wght400_GRAD0_opsz24.svg">
        </button>
        <button @click="openLinkDialog">
          <img alt="" src="@/assets/icon/icons-editor/link_FILL0_wght400_GRAD0_opsz24.svg">
        </button>
        <div class="position-relative">
          <button @click="toggleDropList('bulleted')">
            <img alt="" src="@/assets/icon/icons-editor/format_list_bulleted_FILL0_wght400_GRAD0_opsz24.svg">
          </button>
          <div v-if="isOpenBulleted" class="drop-list">
            <button :class="{ 'is-active': editor.isActive('bulletList') }"
                    @click="editor.chain().focus().toggleBulletList().run()">
              toggleBulletList
            </button>
            <button :disabled="!editor.can().splitListItem('listItem')"
                    @click="editor.chain().focus().splitListItem('listItem').run()">
              splitListItem
            </button>
            <button :disabled="!editor.can().sinkListItem('listItem')"
                    @click="editor.chain().focus().sinkListItem('listItem').run()">
              sinkListItem
            </button>
            <button :disabled="!editor.can().liftListItem('listItem')"
                    @click="editor.chain().focus().liftListItem('listItem').run()">
              liftListItem
            </button>
          </div>
        </div>
        <div class="position-relative">
          <button @click="toggleDropList('number')">
            <img alt="" src="@/assets/icon/icons-editor/format_list_numbered_FILL0_wght400_GRAD0_opsz24.svg">
          </button>
          <div v-if="isOpenNumber" class="drop-list">
            <button :class="{ 'is-active': editor.isActive('orderedList') }"
                    @click="editor.chain().focus().toggleOrderedList().run()">
              toggleOrderedList
            </button>
            <button :disabled="!editor.can().splitListItem('listItem')"
                    @click="editor.chain().focus().splitListItem('listItem').run()">
              splitListItem
            </button>
            <button :disabled="!editor.can().sinkListItem('listItem')"
                    @click="editor.chain().focus().sinkListItem('listItem').run()">
              sinkListItem
            </button>
            <button :disabled="!editor.can().liftListItem('listItem')"
                    @click="editor.chain().focus().liftListItem('listItem').run()">
              liftListItem
            </button>
          </div>
        </div>
        <button class="add-image" @click="addImage">
          <img alt="" src="@/assets/icon/icons-editor/add_a_photo_FILL0_wght400_GRAD0_opsz24.svg">
          add image
        </button>
      </div>
    </div>
    <editor-content :editor="editor"/>
  </div>
</template>

<script>
import {BubbleMenu, Editor, EditorContent} from "@tiptap/vue-2";
import {Heading} from '@tiptap/extension-heading'
import {Paragraph} from "@tiptap/extension-paragraph"
import {Dropcursor} from "@tiptap/extension-dropcursor"
import {StarterKit} from "@tiptap/starter-kit";
import Text from "@tiptap/extension-text";
import TextStyle from "@tiptap/extension-text-style";
import {Color} from "@tiptap/extension-color";
import {Underline} from "@tiptap/extension-underline";
import {TextAlign} from "@tiptap/extension-text-align";
import {Link} from "@tiptap/extension-link";
import {ListItem} from "@tiptap/extension-list-item";
import {BulletList} from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import {HardBreak} from "@tiptap/extension-hard-break";
import Image from "@tiptap/extension-image";
import api from "@/api/api";

export default {
  name: 'EditorWithFilters',
  props: ["tab", "initHtml"],
  components: {
    EditorContent,
    BubbleMenu
  },
  data() {
    return {
      editor: null,
      isOpenNumber: false,
      isOpenBulleted: false,
      html: ""
    }
  },
  methods: {
    handleSelectChange(event) {
      const selectedValue = event.target.value;

      switch (selectedValue) {
        case '4':
          this.editor.chain().focus().toggleHeading({level: 4}).run();
          break;
        case '5':
          this.editor.chain().focus().toggleHeading({level: 5}).run();
          break;
        case '6':
          this.editor.chain().focus().setParagraph().run();
          break;
        default:
          break;
      }
    },
    handleTitle(event) {
      this.editor.chain().focus().toggleHeading({level: 4}).run();
    },
    openLinkDialog() {
      const url = prompt('Enter the URL:');

      if (url) {
        this.editor.chain().focus().setLink({href: url}).run();
      }
    },
    addRedLine() {
      this.editor.chain().focus().setParagraph().updateAttributes({class: 'red-line'}).run();
    },
    toggleDropList(type) {
      if (type === 'bulleted') {
        this.isOpenBulleted = !this.isOpenBulleted;
        this.isOpenNumber = false;
      } else if (type === 'number') {
        this.isOpenNumber = !this.isOpenNumber;
        this.isOpenBulleted = false;
      }
    },
    addImage() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.click();

      input.addEventListener('change', async () => {
        const file = input.files[0];
        if (!file) return;

        if (!file.type.startsWith('image/')) {
          alert('The file is not a valid image.');
          return;
        }

        const formData = new FormData();
        formData.append('file', file);

        api.uploadImage(formData)
            .then((response)=>{
              const imageUrl = process.env.VUE_APP_API_URL+response.data; // replace with actual path to URL in your API response
              this.editor.chain().focus().setImage({ src: imageUrl }).run();
            })
      });
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.initHtml,

      extensions: [
        StarterKit,
        Heading.configure({
          levels: [4, 5],
          HTMLAttributes: {
            class: 'test'
          }
        }),
        Document,
        Paragraph.configure({
          // HTMLAttributes: {
          //   class: 'text-news',
          // },
        }),
        Text,
        TextStyle,
        Color,
        Underline,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Link,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak,
        Document,
        Image,
        Dropcursor,
        Image.configure({
          align: 'center',
        }),
      ],
      onUpdate: () => {
        this.$emit('input-editor', {
              html: this.editor.getHTML(),
              key: this.tab.key
            }
        )
        this.html = this.editor.getHTML()
      }
    })
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .tiptap {
    border: 2px solid grey;
    border-radius: 10px;
    min-height: 300px;
    img {
      max-width: 100%;
    }
  }
}

.btn {
  width: auto;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.filters-group {
  display: flex;
  gap: 1rem;

  .form-select {
    padding: 0.3rem 2rem 0.3rem 0.3rem;
    height: fit-content;
    min-height: fit-content;
  }
}

.bubble-menu {
  display: flex;
  background-color: #0D0D0D;
  padding: 0.2rem;
  border-radius: 0.5rem;
}

.is-active {
  background: #e1e1e1 !important;
  padding: 0.3rem;
  border-radius: 50%;
}

.red-line {
  text-indent: 1.5em;
}

.editor-group {
  display: flex;
  gap: 1rem;
  height: fit-content;

  button {
    background: none;
    border: none;
    outline: none;
    padding: 0.3rem;
    border-radius: 50%;
    position: relative;
  }

  .drop-list {
    position: absolute;
    top: 100%;
    left: 0;
    transform: translate(0, 0);
    padding: 0.5rem;
    background: #FFFFFF;
    z-index: 9;
    border-radius: 4px;

    .is-active {
      border-radius: 4px;
    }
  }

  .add-image {
    background: #e1e1e1;
    border-radius: 4px;
  }
}
</style>
